import { useCallback } from 'react'
import { createPortal } from 'react-dom'

import { Avatar } from '@/components/ui/avatar/Avatar'
import { Tooltip } from '@/components/ui/tooltip/Tooltip'
import { UnassignedIcon } from '@/features/task/components/UnassignedIcon'
import { ChatMessageInputWithNewMessages } from '@/features/task/components/chat/ChatMessageInputWithNewMessages'
import { ChatMessagePayload } from '@/features/task/components/chat/chat-message-payloads/ChatMessagePayload'
import { ImageMessagePayload } from '@/features/task/components/chat/chat-message-payloads/ImageMessagePayload'
import { OtherFilesMessagePayload } from '@/features/task/components/chat/chat-message-payloads/OtherFilesMessagePayload'
import {
  TaskUpdateMessagePayload,
  TaskUpdateMessagePayloadForUser,
} from '@/features/task/components/chat/chat-message-payloads/TaskUpdateMessagePayload'
import { useChat } from '@/features/task/components/chat/useChat'
import { useUsers } from '@/features/task/hooks/useUsers'
import {
  FetchAllUsersQuery,
  GetThreadEventsQuery,
} from '@/gql/generated/graphql'
import { cn, formatMessageTimestamp } from '@/lib/utils'

type Users = FetchAllUsersQuery['users']

const ChatViewEventsThread = ({
  newMessageCallback,
  previousMessageCallback,
  threadEvents,
  users,
}: {
  newMessageCallback: (node: HTMLDivElement) => void
  previousMessageCallback: (node: HTMLDivElement) => void
  threadEvents: GetThreadEventsQuery['tasks'][number]['thread']['events']
  users: Users
}) => {
  return (
    <>
      {threadEvents.map((event, index) => {
        const isNewMessage = threadEvents.length === index + 1
        const isPreviousMessage = threadEvents.length - 2 === index
        const localizedDate = new Date(event.createdAt).toLocaleString()

        const imgFileMetadata = event.payload.filter(
          (payload) => payload.__typename === 'ImageFileMetadata'
        )

        const otherFileMetadata = event.payload.filter(
          (payload) => payload.__typename === 'FileMetadata'
        )

        const message = event.payload.find(
          (payload) => payload.__typename === 'Message'
        )
        const taskUpdate = event.payload.find(
          (payload) => payload.__typename === 'TaskUpdate'
        )

        const authorId = message?.author?.id ?? taskUpdate?.actor?.id
        return (
          <div
            key={event.id}
            data-testid={`message-${event.id}`}
            ref={
              isNewMessage
                ? newMessageCallback
                : isPreviousMessage
                  ? previousMessageCallback
                  : undefined
            }
          >
            <div className="flex items-center gap-4">
              <Avatar
                alt={message?.author?.name ?? taskUpdate?.actor?.name}
                boringAvatarSeed={authorId}
                src={message?.author?.avatarUrl ?? taskUpdate?.actor?.avatarUrl}
                className="size-6 min-h-6 min-w-6 rounded-full"
                FallbackAvatarIcon={authorId ? undefined : UnassignedIcon}
              />

              <div className="flex items-center gap-2 truncate">
                <p className="text-md-bold text-mono-ink-strong">
                  {message?.author?.name ??
                    taskUpdate?.actor?.name ??
                    'Unkown User'}
                </p>

                {taskUpdate && (
                  <TaskUpdateMessagePayloadForUser taskUpdate={taskUpdate} />
                )}

                <Tooltip
                  content={localizedDate}
                  side="top"
                  data-testid={`message-timestamp-tooltip-${message?.id}`}
                >
                  <span
                    className="text-xs-regular text-film-strongest"
                    data-testid={`message-timestamp-${message?.id}`}
                  >
                    {formatMessageTimestamp(event.createdAt)}
                  </span>
                </Tooltip>
              </div>
            </div>

            <div
              className={cn('mt-1 ml-10 w-fit', {
                'rounded-lg border border-film-faint bg-film-subtle p-2':
                  !taskUpdate,
              })}
            >
              {imgFileMetadata.length > 0 && (
                <ImageMessagePayload imageFiles={imgFileMetadata} />
              )}

              {otherFileMetadata.length > 0 && (
                <OtherFilesMessagePayload files={otherFileMetadata} />
              )}

              {!!message && message.body.text && (
                <ChatMessagePayload message={message.body.text} users={users} />
              )}

              {!!taskUpdate && (
                <TaskUpdateMessagePayload taskUpdate={taskUpdate} />
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}
ChatViewEventsThread.displayName = 'ChatViewEventsThread'

export const ChatView = ({
  isRenderedInStackCard,
  taskFooterElement,
  taskId,
}: {
  taskId: string
  isRenderedInStackCard: boolean
  taskFooterElement: HTMLDivElement | null | undefined
}) => {
  const { users } = useUsers()

  const {
    createNewMessage,
    handleScrollToNewMessage,
    hasNewReceivedMessage,
    isCreatingNewMessage,
    newMessageCallback,
    previousMessageCallback,
    thread,
    threadEvents,
  } = useChat<HTMLDivElement>(taskId)

  const handleSendMessage = useCallback(
    (data: { message: string; fileIds?: string[] }) => {
      // consolidate for different string format from react mention
      const formatMentions = (text: string) => {
        const mentionRegex = /@\[(.+?)\]\((.+?)\)/g
        // eslint-disable-next-line unicorn/prefer-string-replace-all
        return text.replace(mentionRegex, '<@U$2> ')
      }

      void (async () => {
        if (thread?.thread.id) {
          await createNewMessage({
            body: {
              fileIds: data.fileIds,
              text: formatMentions(data.message),
            },
            threadId: thread.thread.id,
          })
        }
      })()
    },
    [createNewMessage, thread]
  )

  const handleScrollToNewMessageSync = useCallback(() => {
    handleScrollToNewMessage()
  }, [handleScrollToNewMessage])

  return (
    <div className="flex flex-1 flex-col justify-end overflow-hidden">
      <div
        className="flex flex-col gap-6 overflow-y-auto pb-6"
        data-testid="chat-view"
      >
        <ChatViewEventsThread
          newMessageCallback={newMessageCallback}
          previousMessageCallback={previousMessageCallback}
          threadEvents={threadEvents}
          users={users}
        />
      </div>

      {isRenderedInStackCard && taskFooterElement ? (
        createPortal(
          <ChatMessageInputWithNewMessages
            onNewMessagesButtonClick={handleScrollToNewMessageSync}
            onSendMessage={handleSendMessage}
            disabled={isCreatingNewMessage}
            hasNewReceivedMessage={hasNewReceivedMessage}
          />,
          taskFooterElement
        )
      ) : (
        <ChatMessageInputWithNewMessages
          onNewMessagesButtonClick={handleScrollToNewMessageSync}
          onSendMessage={handleSendMessage}
          disabled={isCreatingNewMessage}
          hasNewReceivedMessage={hasNewReceivedMessage}
        />
      )}
    </div>
  )
}

ChatView.displayName = 'ChatView'
