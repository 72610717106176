import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu'
import { useQueryClient } from '@tanstack/react-query'
import { Link, useSearch } from '@tanstack/react-router'
import {
  Dispatch,
  forwardRef,
  HTMLAttributes,
  useCallback,
  useMemo,
} from 'react'
import { toast } from 'react-toastify'

import { Avatar } from '@/components/ui/avatar/Avatar'
import { useApi } from '@/contexts/ApiProvider'
import { DeleteTasksDialog } from '@/features/task/components/DeleteTaskDialog/DeleteTasksDialog'
import { TaskStatusCheckbox } from '@/features/task/components/TaskStatusCheckbox'
import { ArrowRightIcon } from '@/features/task/components/subtasks/ArrowRightIcon'
import { PasteTasksDialog } from '@/features/task/components/subtasks/PasteTaskDialog'
import { SubtaskPopoverContent } from '@/features/task/components/subtasks/SubtaskPopoverContent'
import { SubtaskPopoverRenameForm } from '@/features/task/components/subtasks/SubtaskPopoverRenameForm'
import {
  SubtaskListAction,
  subtaskListSelectorsFactory,
  SubtaskListState,
} from '@/features/task/components/subtasks/reducer'
import { createTaskQueryOptions, TaskData } from '@/features/task/hooks/useTask'
import { useTaskStack } from '@/features/task/hooks/useTaskStack'
import { TaskStatus } from '@/gql/generated/graphql'
import { useHighlightableListItem } from '@/lib/hooks/use-highlightable-list/useHighlightableList'
import { useDocumentKeyCapture } from '@/lib/hooks/useDocumentKeyCapture'
import { usePasteEvent } from '@/lib/hooks/usePasteEvent'
import { useTaskItemKeyNavigation } from '@/lib/hooks/useTaskItemKeyNavigation'
import { cn, isMac, mergeRefs } from '@/lib/utils'

type SubtaskData = TaskData['subtasks'][number]

type SubtaskWithPopoverProperties = HTMLAttributes<HTMLDivElement> & {
  onDelete: (ids: string[]) => void
  onMove: (id: string) => void
  onRename: (title: string) => void
  onToggleComplete: () => void
  onTaskStatusChange: (status: TaskStatus) => void
  handleArrowUpDown: (event: KeyboardEvent) => boolean
  task: SubtaskData
  state: SubtaskListState
  dispatch: Dispatch<SubtaskListAction>
  parentId: string
}

const deleteReferenceKeys = ['Meta', 'Backspace']

const macOpenDialogKeyCombination = ['Meta', 'k']
const otherOpenDialogKeyCombination = ['Control', 'k']

const macCopyTaskUrlKeys = ['Meta', 'c']
const windowsCopyTaskUrlKeys = ['Control', 'c']

export const SubtaskWithPopover = forwardRef<
  HTMLDivElement,
  SubtaskWithPopoverProperties
>(
  (
    {
      className,
      dispatch,
      handleArrowUpDown,
      onDelete,
      onMove,
      onRename,
      onTaskStatusChange,
      onToggleComplete,
      parentId,
      state,
      task,
      ...properties
    },
    reference
  ) => {
    const api = useApi()
    const queryClient = useQueryClient()

    const selectors = subtaskListSelectorsFactory(state)
    const selectedItems = selectors.getSelectedItems()
    const isMultiselectActive = selectedItems.length > 1
    const isHighlighted = selectors.isHighlighted(task.id)
    const isHighlightCursor = selectors.isHighlightCursor(task.id)
    const isNextHighlighted = selectors.isNextHighlighted(task.id)
    const isPreviousHighlighted = selectors.isPreviousHighlighted(task.id)

    const { stack } = useSearch({
      from: '/_private',
    })
    const isStackPresent = stack !== undefined && stack.length > 0
    const isTopLevelTask = stack?.at(-1) === parentId

    const {
      attributes,
      isDragging,
      listeners,
      setNodeRef,
      transform,
      transition,
    } = useSortable({ disabled: isMultiselectActive, id: task.id })
    const isDeleteDialogVisibleForCurrentTask =
      selectors.isDeleteDialogVisibleForTask(task.id)

    const isRenaming = selectors.isRenamingGivenTask(task.id)

    const isPasteDialogVisible = selectors.isPasteDialogVisible(task.id)

    const style = {
      position: 'relative' as const,
      transform: CSS.Transform.toString(transform),
      transition,
      zIndex: isDragging ? 1 : 0,
    }

    const { handleOpenStack, navigateToTask } = useTaskStack(
      task.id,
      state.subtasks.map((subtask) => subtask.id)
    )

    const handleDeleteDialogClose = useCallback(() => {
      dispatch({ type: 'onDeleteDialogClose' })
    }, [dispatch])

    const handleDeleteDialogOpen = useCallback(() => {
      dispatch({ type: 'onDeleteDialogOpen' })
    }, [dispatch])

    const handlePasteDialogClose = useCallback(() => {
      dispatch({ type: 'onPasteDialogClose' })
    }, [dispatch])

    const handleCopyToClipboard = useCallback(() => {
      void navigator.clipboard.writeText(
        `${location.origin}/all-work/${task.id}`
      )
      dispatch({ type: 'onContextMenuClose' })
      toast.success('Link copied to clipboard')
    }, [task.id, dispatch])

    const handlePaste = useCallback(
      (pastedText: string) => {
        if (pastedText.trim() === '') {
          return
        }
        if (selectedItems.length === 1) {
          const taskUrlSegments = new URL(pastedText)?.pathname.split('/')
          const taskId = taskUrlSegments.at(-1)
          const isSubtaskAlreadyPresent = state.subtasks.some(
            (subtask) => subtask.id === taskId
          )

          if (isSubtaskAlreadyPresent) {
            toast.info('The task is already present', {
              toastId: 'subtask-already-present',
            })
            return
          }

          if (taskId) {
            const queryOptions = createTaskQueryOptions({ api, taskId })
            void queryClient.fetchQuery({ ...queryOptions }).then((data) => {
              dispatch({ payload: data, type: 'onPasteDialogOpen' })
            })
          }
        }
      },
      [selectedItems.length, state.subtasks, api, queryClient, dispatch]
    )

    const handlePasteByButtonClick = useCallback(() => {
      void navigator.clipboard
        .readText()
        .then(handlePaste)
        .catch((error: Error) => {
          toast.error(error.message)
        })
    }, [handlePaste])

    const handlePasteByEvent = useCallback(
      (event: ClipboardEvent) => {
        if (event.clipboardData) {
          const pastedText = event.clipboardData.getData('text')
          handlePaste(pastedText)
        }
      },
      [handlePaste]
    )

    const areListenersActive =
      !isRenaming &&
      !state.isContextMenuOpen &&
      !state.isDeleteDialogOpen &&
      (!isStackPresent || isTopLevelTask)

    usePasteEvent(handlePasteByEvent)

    useDocumentKeyCapture(
      deleteReferenceKeys,
      handleDeleteDialogOpen,
      areListenersActive && isHighlightCursor
    )

    useDocumentKeyCapture(
      'Delete',
      handleDeleteDialogOpen,
      areListenersActive && isHighlightCursor
    )

    const openTaskConditions =
      isHighlightCursor &&
      !state.isContextMenuOpen &&
      !isRenaming &&
      !isMultiselectActive &&
      !isDeleteDialogVisibleForCurrentTask &&
      !isPasteDialogVisible

    useTaskItemKeyNavigation(
      navigateToTask,
      handleOpenStack,
      openTaskConditions && areListenersActive
    )

    const blurSuspended =
      (isStackPresent && !isTopLevelTask) ||
      state.isContextMenuOpen ||
      state.isDeleteDialogOpen ||
      state.isRenamingSubtask

    const highlightableItemControls = useHighlightableListItem({
      blurSuspended,
      dispatch,
      id: task.id,
      isArrowListenerActive: areListenersActive,
      onArrowUpDown: handleArrowUpDown,
      state,
    })

    const handleContextMenuOpen = useCallback(() => {
      if (highlightableItemControls.ref.current) {
        const target = highlightableItemControls.ref.current

        target.dispatchEvent(
          new MouseEvent('contextmenu', {
            bubbles: true,
            clientX: target.getBoundingClientRect().x,
            clientY:
              target.getBoundingClientRect().height +
              target.getBoundingClientRect().y,
          })
        )
      }
    }, [highlightableItemControls.ref])

    useDocumentKeyCapture(
      isMac() ? macOpenDialogKeyCombination : otherOpenDialogKeyCombination,
      handleContextMenuOpen,
      isHighlightCursor && areListenersActive && !isMultiselectActive
    )

    useDocumentKeyCapture(
      isMac() ? macCopyTaskUrlKeys : windowsCopyTaskUrlKeys,
      handleCopyToClipboard,
      isHighlightCursor && areListenersActive && !isMultiselectActive
    )

    const onDeleteTask = useCallback(() => {
      handleDeleteDialogOpen()
    }, [handleDeleteDialogOpen])

    const onSetRenaming = useCallback(() => {
      // timeout is needed to prevent the SubtaskWithPopoverRenameForm registering the onOutsideClick and closing itself immediately
      setTimeout(() => {
        dispatch({ type: 'onRenameSubtask' })
      })
    }, [dispatch])

    const onSetNotRenaming = useCallback(() => {
      dispatch({ type: 'onRenamingSubtaskClose' })
    }, [dispatch])

    const linkParameters = useMemo(
      () => ({
        taskId: task.id,
      }),
      [task.id]
    )

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement>) => {
        if (
          (event.target as HTMLElement)?.closest?.(`[id="${task.id}-checkbox"]`)
        ) {
          return
        }

        // Only handle left clicks
        if (event.button === 0) {
          highlightableItemControls.onClick()
          handleOpenStack()
        }
      },
      [task.id, highlightableItemControls, handleOpenStack]
    )

    return (
      <div ref={setNodeRef} style={style}>
        {isRenaming && (
          <SubtaskPopoverRenameForm
            isCompleted={!!task.completedAt}
            isRenaming={isRenaming}
            onRename={onRename}
            setNotRenaming={onSetNotRenaming}
            taskId={task.id}
            title={task.title}
          />
        )}

        <ContextMenuPrimitive.Root modal>
          <ContextMenuPrimitive.Trigger asChild>
            <div
              className="relative z-50 outline-hidden"
              {...properties}
              ref={mergeRefs(reference, highlightableItemControls.ref)}
              id={task.id}
              onMouseDown={handleClick}
              onBlur={highlightableItemControls.onBlur}
              onFocus={highlightableItemControls.onFocus}
            >
              <div
                className={cn(
                  'flex h-11 cursor-pointer items-center justify-between py-3.25 pr-9 pl-9',
                  {
                    'bg-film-strong': state.isContextMenuOpen,
                    'border border-sky-300 bg-sky-50 outline-hidden':
                      isHighlighted,
                    'rounded-b-lg': isHighlighted && !isNextHighlighted,
                    'rounded-b-none border-b-transparent':
                      isHighlighted && isNextHighlighted,
                    'rounded-lg border border-transparent bg-mono-paper':
                      !isHighlighted,
                    'rounded-t-lg': isHighlighted && !isPreviousHighlighted,
                    'rounded-t-none border-t-transparent':
                      isHighlighted && isPreviousHighlighted,
                  },
                  className
                )}
                {...listeners}
                {...attributes}
                tabIndex={-1}
              >
                <div className="flex w-full justify-between pr-3">
                  <p
                    className={cn('overflow-hidden text-nowrap text-ellipsis', {
                      'text-mono-ink-subtle line-through':
                        task.status === 'CANCELLED',
                    })}
                  >
                    {task.title}
                  </p>
                  {task.assignee && (
                    <Avatar
                      src={task.assignee.avatarUrl}
                      alt={task.assignee.name}
                      tooltip={task.assignee.name}
                      boringAvatarSeed={task.assignee.id}
                      className="size-5 max-w-none rounded"
                    />
                  )}
                </div>
              </div>
              <TaskStatusCheckbox
                id={task.id}
                data-testid="checkbox"
                status={task.status || 'OPEN'}
                onTaskStatusChange={onTaskStatusChange}
                className="absolute top-1/2 left-3.25 z-10 -translate-y-1/2 transform rounded-full"
              />

              <Link
                data-testid={`open-subtask-${task.id}`}
                className="absolute top-1/2 right-3.25 z-10 flex h-6 w-6 min-w-6 -translate-y-1/2 transform items-center justify-center rounded-full bg-film-subtle focus:rounded-lg focus:bg-sky-50 focus:outline focus:outline-sky-300"
                to="/all-work/$taskId"
                params={linkParameters}
              >
                <ArrowRightIcon className="size-3" />
                <span className="sr-only">Go to {task.title} task</span>
              </Link>
            </div>
          </ContextMenuPrimitive.Trigger>

          <ContextMenuPrimitive.Portal>
            <SubtaskPopoverContent
              assigneeId={task.assignee?.id}
              isCompleted={task.status === 'COMPLETED'}
              onTaskStatusChange={onTaskStatusChange}
              onCopyToClipboard={handleCopyToClipboard}
              onDelete={onDeleteTask}
              onRename={onSetRenaming}
              onOpenTask={navigateToTask}
              onPasteTask={handlePasteByButtonClick}
              title={task.title}
              taskId={task.id}
            />
          </ContextMenuPrimitive.Portal>
        </ContextMenuPrimitive.Root>

        <DeleteTasksDialog
          itemsToDelete={selectedItems}
          onDeleteClick={onDelete}
          isDialogOpen={isDeleteDialogVisibleForCurrentTask}
          onOpenChange={handleDeleteDialogClose}
        />

        {state.copiedTask && (
          <PasteTasksDialog
            taskToMove={state.copiedTask}
            onMoveTasksClick={onMove}
            isDialogOpen={isPasteDialogVisible}
            onOpenChange={handlePasteDialogClose}
          />
        )}
      </div>
    )
  }
)

SubtaskWithPopover.displayName = 'SubtaskWithPopover'
