import { useSuspenseQuery } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'

import { useApi } from '@/contexts/ApiProvider'

interface CreateQueryOptionParameters {
  api: RouteContext['api']
}

export const createUsersQueryOptions = ({
  api,
}: CreateQueryOptionParameters) => {
  return {
    queryFn: async () => {
      const { users } = await api.fetchAllUsers()

      return users.sort((a, b) => a.name.localeCompare(b.name))
    },
    queryKey: ['allUsers'],
  }
}

export const useUsers = () => {
  const api = useApi()
  const queryOptions = createUsersQueryOptions({ api })
  const { data } = useSuspenseQuery(queryOptions)

  return { users: data }
}
