import { IconComponent } from '@/lib/types'

export const FileIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...properties}
    >
      <path
        fill="inherit"
        d="M6.25 2A2.26 2.26 0 0 0 4 4.25v15.5A2.26 2.26 0 0 0 6.25 22h11.5A2.26 2.26 0 0 0 20 19.75V9.25a.75.75 0 0 0-.219-.531l-6.5-6.5A.75.75 0 0 0 12.75 2zm0 1.5H12v4.25A2.26 2.26 0 0 0 14.25 10h4.25v9.75c0 .424-.326.75-.75.75H6.25a.737.737 0 0 1-.75-.75V4.25c0-.424.326-.75.75-.75m7.25 1.06 3.94 3.94h-3.19a.737.737 0 0 1-.75-.75z"
      />
    </svg>
  )
}

FileIcon.displayName = 'FileIcon'
