import { formatDistance } from 'date-fns/formatDistance'
import { useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'

import { Button } from '@/components/ui/button/Button'
import { Switch } from '@/components/ui/switch/Switch'
import { TaskIcon } from '@/features/task/components/TaskIcon'
import { RefineTaskMutation } from '@/gql/generated/graphql'
import { isNotNil } from '@/lib/utils'

type PrecedentTask = RefineTaskMutation['refineTask']['precedentTasks'][number]

const PrecedentTaskItem = ({
  onSelectionChange,
  selection,
  task,
}: {
  task: PrecedentTask
  selection: string[]
  onSelectionChange: (taskId: string, selected: boolean) => void
}) => {
  const handleCheckedChange = useCallback(
    (isChecked: boolean) => {
      onSelectionChange(task.id, isChecked)
    },
    [onSelectionChange, task.id]
  )

  const details = useMemo(() => {
    const assignee = task.assignee?.name ?? 'No assignee'
    if (!task.completedAt) {
      return assignee
    }

    const elapsed = `Completed within ${formatDistance(task.completedAt, task.createdAt)}`
    return `${assignee} · ${elapsed}, ${new Date(task.completedAt).toLocaleDateString()}`
  }, [task.assignee?.name, task.completedAt, task.createdAt])

  return (
    <div className="flex h-16 items-stretch rounded-xl border-[0.5px] border-film-normal bg-mono-paper">
      <div className="flex items-center justify-center border-r-[0.5px] border-r-film-normal px-3">
        <Switch
          id={`use-task-${task.id}`}
          data-testid={`use-task-${task.id}`}
          onCheckedChange={handleCheckedChange}
          checked={selection.includes(task.id)}
        />
      </div>
      <div className="flex gap-2 p-4">
        <div className="flex h-8 w-8 items-center justify-center rounded-sm bg-film-subtle">
          <TaskIcon status="COMPLETED" size="md" />
        </div>
        <div>
          <h3 className="overflow-hidden text-sm leading-[1.2] text-nowrap text-ellipsis text-mono-ink-strong">
            {task.title}
          </h3>
          <p className="overflow-hidden text-xs font-medium text-nowrap text-ellipsis text-mono-ink-subtle">
            {details}
          </p>
        </div>
      </div>
    </div>
  )
}

PrecedentTaskItem.displayName = 'PrecedentTaskItem'

export const SubtasksPlanningPrecedentSelection = ({
  hideChatOverlay,
  onSelectPrecedent,
  precedentTasks,
  selectedPrecedents,
}: {
  hideChatOverlay: () => void
  onSelectPrecedent: (taskId: string, selected: boolean) => void
  precedentTasks: PrecedentTask[]
  selectedPrecedents: string[]
}) => {
  const chatOverlay = document.querySelector('#chat-overlay')

  return (
    chatOverlay &&
    createPortal(
      <>
        <div className="border-b-[0.5px] border-b-film-normal p-5">
          <h2 className="mb-2 text-2xl font-medium">Automatic plan settings</h2>
          <p className="font-normal text-mono-ink-subtle">
            Select which previously completed tasks to use as inspiration for
            your plan.
          </p>
        </div>
        <div className="flex flex-1 flex-col gap-1 overflow-auto p-5">
          {precedentTasks
            .filter((value) => isNotNil(value))
            .map((task) => (
              <PrecedentTaskItem
                task={task}
                key={task.id}
                selection={selectedPrecedents}
                onSelectionChange={onSelectPrecedent}
              />
            ))}
        </div>
        <div className="flex justify-end border-t-[0.5px] border-t-film-normal p-5">
          <Button
            variant={'subtle'}
            size={'sm'}
            onClick={hideChatOverlay}
            data-testid="chat-overlay-done-button"
          >
            Done
          </Button>
        </div>
      </>,
      chatOverlay
    )
  )
}

SubtasksPlanningPrecedentSelection.displayName = 'SubtasksPlanning'
