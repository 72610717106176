import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from '@tanstack/react-router'
import {
  FlagProvider,
  IFlagProvider,
  useUnleashClient,
} from '@unleash/proxy-client-react'
import React, { ComponentProps, FC, useCallback, useMemo } from 'react'

import { ErrorFallback } from '@/components/errors/errorFallback'
import { ApiProvider, useApi } from '@/contexts/ApiProvider'
import { OidcAuthProvider, useOIDCAuth } from '@/contexts/OidcAuthContext'
import { ConfigurationVariables } from '@/lib/configurationVariablesSchemaValidator'
import { logger } from '@/lib/logger'
import { router as appRouter } from '@/router'

interface RouterProviderProperties
  extends Partial<ComponentProps<typeof RouterProvider>> {
  context?: {
    queryClient: QueryClient
  }
}

interface Properties {
  configurationVariables: ConfigurationVariables
  queryClientProviderProperties?: Partial<
    ComponentProps<typeof QueryClientProvider>
  >
  routerProviderProperties?: RouterProviderProperties
  unleashProviderProperties?: IFlagProvider
}

const RouterProviderWithContext: FC<ComponentProps<typeof RouterProvider>> = ({
  context: passedContext,
  ...rest
}) => {
  const api = useApi()
  const unleashClient = useUnleashClient()
  const { getUser } = useOIDCAuth()

  const context = useMemo(
    () => ({
      api,
      getUser,
      unleashClient,
      ...passedContext,
    }),
    [api, getUser, unleashClient, passedContext]
  )

  return <RouterProvider context={context} {...rest} />
}
RouterProviderWithContext.displayName = 'RouterProviderWithContext'

export const Entry: FC<Properties> = ({
  configurationVariables,
  queryClientProviderProperties,
  routerProviderProperties = {},
  unleashProviderProperties,
}) => {
  const {
    context = {
      queryClient: new QueryClient(),
    },
    router = appRouter,
    ...restRouterProviderProperties
  } = routerProviderProperties

  const fullContext = useMemo(
    () => ({
      ...context,
      configurationVariables,
    }),
    [context, configurationVariables]
  )

  const defaultOnCatch = useCallback((error: unknown) => {
    logger.error(error)
  }, [])

  return (
    <React.StrictMode>
      <QueryClientProvider
        client={context.queryClient}
        {...queryClientProviderProperties}
      >
        <FlagProvider {...unleashProviderProperties}>
          <OidcAuthProvider configurationVariables={configurationVariables}>
            <ApiProvider>
              <RouterProviderWithContext
                context={fullContext}
                defaultErrorComponent={ErrorFallback}
                defaultOnCatch={defaultOnCatch}
                router={router}
                {...restRouterProviderProperties}
              />
            </ApiProvider>
          </OidcAuthProvider>
        </FlagProvider>
      </QueryClientProvider>
    </React.StrictMode>
  )
}
Entry.displayName = 'Entry'
