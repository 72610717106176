import { FetchTasksByIdQuery } from '@/gql/generated/graphql'
import {
  HighlightableListState,
  highlightableListSelectorsFactory,
  highlightableListActionHandlers,
} from '@/lib/hooks/use-highlightable-list/state'

type SubtaskData = FetchTasksByIdQuery['tasks'][number]['subtasks'][number]

export interface SubtaskListState extends HighlightableListState {
  subtasks: SubtaskData[]
  copiedTask?: SubtaskData
  isContextMenuOpen: boolean
  isDeleteDialogOpen: boolean
  isRenamingSubtask: boolean
}

export type SubtaskListAction =
  | {
      type: 'onInit' | 'onListUpdate'
      payload: SubtaskData[]
    }
  | {
      type: 'onRealFocusChange'
      payload: string | undefined
    }
  | { type: 'onPasteDialogOpen'; payload: SubtaskData }
  | { type: 'onSubtaskMoved'; payload: string }
  | {
      type:
        | 'onContextMenuOpen'
        | 'onDeleteDialogOpen'
        | 'onContextMenuClose'
        | 'onPasteDialogClose'
        | 'onDeleteDialogClose'
        | 'onShiftArrowUp'
        | 'onShiftArrowDown'
        | 'onArrowUp'
        | 'onArrowDown'
        | 'onRenameSubtask'
        | 'onRenamingSubtaskClose'
    }

export const subtasksReducer = (
  state: SubtaskListState,
  action: SubtaskListAction
): SubtaskListState => {
  const extendState = (update: Partial<SubtaskListState>) => {
    return {
      ...state,
      ...update,
    }
  }
  const actionHandlers = highlightableListActionHandlers(state)

  switch (action.type) {
    case 'onInit': {
      return extendState({
        ...actionHandlers.onInit(action.payload.map((subtask) => subtask.id)),
        subtasks: action.payload,
      })
    }
    case 'onRealFocusChange': {
      if (
        action.payload === undefined &&
        (state.isContextMenuOpen ||
          state.isDeleteDialogOpen ||
          state.copiedTask)
      ) {
        return state
      }

      return extendState(actionHandlers.onRealFocusChange(action.payload))
    }
    case 'onContextMenuOpen': {
      return extendState({
        isContextMenuOpen: true,
      })
    }
    case 'onContextMenuClose': {
      return extendState({
        isContextMenuOpen: false,
      })
    }
    case 'onDeleteDialogOpen': {
      return extendState({
        isContextMenuOpen: false,
        isDeleteDialogOpen: true,
      })
    }
    case 'onDeleteDialogClose': {
      return extendState({
        isDeleteDialogOpen: false,
      })
    }
    case 'onPasteDialogOpen': {
      return extendState({
        copiedTask: action.payload,
        isContextMenuOpen: false,
      })
    }
    case 'onPasteDialogClose': {
      return extendState({
        copiedTask: undefined,
      })
    }
    case 'onSubtaskMoved': {
      return extendState({
        highlightCursor: action.payload,
      })
    }
    case 'onShiftArrowUp': {
      if (!state.isDeleteDialogOpen && !state.isContextMenuOpen) {
        return extendState(actionHandlers.onShiftArrowUp())
      }

      return state
    }
    case 'onShiftArrowDown': {
      if (!state.isDeleteDialogOpen && !state.isContextMenuOpen) {
        return extendState(actionHandlers.onShiftArrowDown())
      }

      return state
    }
    case 'onArrowUp': {
      return extendState(actionHandlers.onArrowUp())
    }
    case 'onArrowDown': {
      return extendState(actionHandlers.onArrowDown())
    }
    case 'onListUpdate': {
      return extendState({
        items: action.payload.map((subtask) => subtask.id),
        subtasks: action.payload,
      })
    }
    case 'onRenameSubtask': {
      return extendState({
        isContextMenuOpen: false,
        isRenamingSubtask: true,
      })
    }
    case 'onRenamingSubtaskClose': {
      return extendState({
        isRenamingSubtask: false,
      })
    }
    default: {
      return state
    }
  }
}

export const subtaskListSelectorsFactory = (state: SubtaskListState) => {
  const highlightableListSelectors = highlightableListSelectorsFactory(state)

  const Selectors = {
    getHighlightStartIndex: () => {
      return highlightableListSelectors.getHighlightStartIndex()
    },
    getSelectedItems: () => {
      const items = highlightableListSelectors.getHighlightedItems()
      return state.subtasks.filter((subtask) => items.includes(subtask.id))
    },
    isContextMenuVisibleForTask: (id: string) => {
      return state.isContextMenuOpen && state.highlightCursor === id
    },
    isDeleteDialogVisibleForTask: (id: string) => {
      return state.isDeleteDialogOpen && state.highlightCursor === id
    },
    isHighlightCursor: (id: string) => {
      return highlightableListSelectors.isHighlightCursor(id)
    },
    isHighlighted: (id: string) => {
      return highlightableListSelectors.isHighlighted(id)
    },
    isNextHighlighted: (id: string) => {
      return highlightableListSelectors.isNextHighlighted(id)
    },
    isPasteDialogVisible: (id: string) => {
      return Boolean(state.copiedTask) && state.highlightCursor === id
    },
    isPreviousHighlighted: (id: string) => {
      return highlightableListSelectors.isPreviousHighlighted(id)
    },
    isRenamingGivenTask: (id: string) => {
      return state.isRenamingSubtask && state.highlightCursor === id
    },
  }
  return Selectors
}
