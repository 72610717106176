import { EmptyState } from '@/features/index/components/EmptyState'
import { Notification } from '@/features/notification/components/Notification'
import { useViewerWithNotifications } from '@/features/notification/hooks/useViewerWithNotifications'

export const NotificationList = ({
  hideNotificationSidebar,
}: {
  hideNotificationSidebar: () => void
}) => {
  const { viewer } = useViewerWithNotifications()

  return (
    <div className="flex flex-1 flex-col gap-3 overflow-auto p-12">
      {viewer.notifications.length === 0 ? (
        <EmptyState title="No updates" subtitle="You have no new updates." />
      ) : (
        viewer.notifications.map((notification, index) => (
          <Notification
            notification={notification}
            onClick={hideNotificationSidebar}
            key={index}
            index={index}
            deletable
            className="shadow-xs"
          />
        ))
      )}
    </div>
  )
}

NotificationList.displayName = 'NotificationList'
