import { FC, Suspense, useCallback, useMemo } from 'react'

import { NotesStickyIcon } from '@/components/icons/NotesStickyIcon'
import { EditableTitle } from '@/components/ui/editable-title/EditableTitle'
import { EditableTitleSkeleton } from '@/components/ui/editable-title/EditableTitleSkeleton'
import { useNotes } from '@/features/library/hooks/useNotes'
import { useUpdateNote } from '@/features/library/hooks/useUpdateNote'

interface NoteTitleProperties {
  id: string
  className?: string
}

const NoteTitle: FC<NoteTitleProperties> = ({ className, id }) => {
  const { note } = useNotes(id)
  const { mutate: mutateNote } = useUpdateNote()

  const handleTitleUpdate = useCallback(
    (title: string) => {
      mutateNote({ id, title })
    },
    [id, mutateNote]
  )

  return (
    <EditableTitle
      className={className}
      defaultTitle={note.title}
      label="Rename note"
      icon={useMemo(
        () => (
          <NotesStickyIcon className="h-9 w-9 rounded-sm bg-film-subtle p-1" />
        ),
        []
      )}
      titleOnSubmit={handleTitleUpdate}
    />
  )
}

NoteTitle.displayName = 'NoteTitle'

const SuspendedNoteTitle: FC<NoteTitleProperties> = (properties) => {
  return (
    <Suspense
      fallback={useMemo(
        () => (
          <EditableTitleSkeleton className={properties.className} />
        ),
        [properties.className]
      )}
    >
      <NoteTitle {...properties} />
    </Suspense>
  )
}

SuspendedNoteTitle.displayName = 'SuspendedNoteTitle'

export { SuspendedNoteTitle as NoteTitle }
