import { useQuery, useQueryClient } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'
import orderBy from 'lodash/orderBy'
import { useCallback, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { useCreateChatMessage } from '@/features/task/components/chat/useCreateChatMessage'
import { useTaskDetailPageContext } from '@/features/task/hooks/useTaskDetailPageContext'
import {
  CreateMessageInput,
  GetThreadEventsQuery,
} from '@/gql/generated/graphql'
import { useViewer } from '@/lib/hooks/useViewer'
import { logger } from '@/lib/logger'

const MESSAGES_REFETCH_INTERVAL = 1000

interface CreateFetchTaskMessagesOptions {
  api: RouteContext['api']
  taskId: string
}

const createFetchTaskThreadEventsOptions = ({
  api,
  taskId,
}: CreateFetchTaskMessagesOptions) => {
  return {
    queryFn: async () => {
      const response = await api.fetchTaskThreadEvents(taskId)
      return response[0]
    },
    queryKey: ['messages', taskId],
  }
}

type ThreadEvents = GetThreadEventsQuery['tasks'][number]

export const useChat = <T extends HTMLElement>(taskId: string) => {
  const { viewer } = useViewer()
  const { api } = useTaskDetailPageContext()
  const queryKey = useMemo(() => ['messages', taskId], [taskId])
  const queryClient = useQueryClient()

  const newReceivedMessageReference = useRef<T>()
  const newMessageObserver = useRef<IntersectionObserver>()
  const previousMessageObserver = useRef<IntersectionObserver>()
  const previousMessagesCount = useRef<number>(0)

  const [isMessageInView, setIsMessageInView] = useState(false)
  const [hasNewReceivedMessage, setHasNewReceivedMessage] = useState(false)

  const handleScrollToNewMessage = useCallback(
    (options?: ScrollIntoViewOptions) => {
      newReceivedMessageReference.current?.scrollIntoView(
        options ?? { behavior: 'smooth' }
      )
    },
    []
  )

  const handleMutate = useCallback(
    async (variables: CreateMessageInput) => {
      await queryClient.cancelQueries({ queryKey })

      const previousThread = queryClient.getQueryData<ThreadEvents>(queryKey)

      if (!previousThread || !viewer) return

      const messagePayload = variables.body.text
        ? [
            {
              __typename: 'Message',
              author: {
                avatarUrl: viewer.avatarUrl,
                id: viewer.id,
                name: viewer.name,
              },
              body: { text: variables.body.text },
              id: `not-synced-${Date.now()}`,
            },
          ]
        : []

      const filePayload =
        variables.body.fileIds?.map((fileId) => ({
          __typename: 'FileMetadata',
          fileUrl: `/api/v1/files/${fileId}`,
          name: 'New Upload',
          size: 0,
          type: 'unknown',
        })) ?? []

      queryClient.setQueryData(queryKey, {
        ...previousThread,
        thread: {
          ...previousThread.thread,
          events: [
            ...previousThread.thread.events,
            {
              createdAt: new Date().toISOString(),
              id: `not-synced-thread-event-${Date.now()}`,
              payload: [...messagePayload, ...filePayload],
            },
          ],
        },
      })

      setIsMessageInView(true)
      handleScrollToNewMessage({ behavior: 'instant' })

      return previousThread
    },
    [queryClient, queryKey, viewer, handleScrollToNewMessage]
  )

  const handleError = useCallback(
    async (
      error: Error,
      _variables: CreateMessageInput,
      previousThread?: ThreadEvents | null
    ) => {
      if (previousThread) {
        queryClient.setQueryData(queryKey, previousThread)
      }

      logger.error(error)
      toast.error(error.message, {
        toastId: error.message,
      })
    },
    [queryClient, queryKey]
  )

  const handleSettled = useCallback(() => {
    handleScrollToNewMessage({ behavior: 'instant' })
  }, [handleScrollToNewMessage])

  const { createNewMessage, isPending } = useCreateChatMessage({
    handleError,
    handleMutate,
    handleSettled,
  })

  const threadEventsData = useQuery({
    ...createFetchTaskThreadEventsOptions({ api, taskId }),
    refetchInterval: MESSAGES_REFETCH_INTERVAL,
  })

  const threadEvents = useMemo(
    () =>
      orderBy(
        threadEventsData.data?.thread?.events ?? [],
        (message) => new Date(`${message.createdAt}`),
        'asc'
      ),
    [threadEventsData]
  )

  const previousMessageCallback = useCallback((node: T) => {
    // Scroll to bottom of chat on initialisation
    if (previousMessagesCount.current === 0) {
      node?.scrollIntoView({
        behavior: 'instant',
      })

      return
    }

    if (previousMessageObserver.current) {
      previousMessageObserver.current.disconnect()
    }

    previousMessageObserver.current = new IntersectionObserver((entries) => {
      // Scroll to newly received message if last message is in view
      if (entries[0]?.isIntersecting) {
        entries[0].target.nextElementSibling?.scrollIntoView({
          behavior: 'instant',
        })
        setIsMessageInView(true)
      }
    })

    if (node) {
      previousMessageObserver.current.observe(node)
    }
  }, [])

  const newMessageCallback = useCallback(
    (node: T) => {
      const messages = threadEvents

      if (messages.length === 0) return

      if (newMessageObserver.current) {
        newMessageObserver.current.disconnect()
      }

      newMessageObserver.current = new IntersectionObserver((entries) => {
        // Determine when new message is received if new message is out of scroll
        if (entries[0]?.isIntersecting) return

        if (previousMessagesCount.current === 0) {
          previousMessagesCount.current = messages.length
          return
        }

        if (previousMessagesCount.current < messages.length) {
          setHasNewReceivedMessage(true)
          previousMessagesCount.current = messages.length
        } else {
          setIsMessageInView(false)
          setHasNewReceivedMessage(false)
        }
      })

      if (node) {
        newMessageObserver.current.observe(node)
        newReceivedMessageReference.current = node
      }
    },
    [threadEvents]
  )

  return {
    createNewMessage,
    handleScrollToNewMessage,
    hasNewReceivedMessage: !isMessageInView && hasNewReceivedMessage,
    isCreatingNewMessage: isPending,
    newMessageCallback,
    previousMessageCallback,
    thread: threadEventsData.data,
    threadEvents,
  }
}
