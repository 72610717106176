import * as ContextMenuPrimitive from '@radix-ui/react-context-menu'
import * as React from 'react'

import { CheckedCircleSolidIcon } from '@/components/icons/CheckedCircleSolidIcon'
import { CircleCrossFilledIcon } from '@/components/icons/CircleCrossFilledIcon'
import { CopyLinkToClipboardIcon } from '@/components/icons/CopyLinkToClipboardIcon'
import { PasteClipboardIcon } from '@/components/icons/PasteClipboardIcon'
import { TaskCircularCheckboxEmptyIcon } from '@/components/icons/TaskCircularCheckboxEmptyIcon'
import { Avatar } from '@/components/ui/avatar/Avatar'
import { Button } from '@/components/ui/button/Button'
import { RadioCircleSolidTinted } from '@/components/ui/select/RadioCircleSolidTinted'
import { UnassignedIcon } from '@/features/task/components/UnassignedIcon'
import { ArrowRightIcon } from '@/features/task/components/subtasks/ArrowRightIcon'
import { RenameIcon } from '@/features/task/components/subtasks/RenameIcon'
import { TrashIcon } from '@/features/task/components/subtasks/TrashIcon'
import { useUpdateTask } from '@/features/task/hooks/useUpdateTask'
import { useUsers } from '@/features/task/hooks/useUsers'
import { TaskStatus } from '@/gql/generated/graphql'

interface SubtaskPopoverContentProperties {
  assigneeId?: string | null
  taskId: string
  title: string
  onCopyToClipboard: () => void
  onDelete: () => void
  onRename: () => void
  onTaskStatusChange: (status: TaskStatus) => void
  onOpenTask: () => void
  onPasteTask?: () => void
  isCompleted: boolean
}

export const SubtaskPopoverContent = React.forwardRef<
  HTMLDivElement,
  SubtaskPopoverContentProperties
>(
  (
    {
      assigneeId,
      isCompleted,
      onCopyToClipboard,
      onDelete,
      onOpenTask,
      onPasteTask,
      onRename,
      onTaskStatusChange,
      taskId,
      title,
      ...rest
    },
    reference
  ) => {
    const popoverElements = React.useMemo(
      () => [
        {
          content: (
            <>
              <TaskCircularCheckboxEmptyIcon className="size-6" />
              Mark open
            </>
          ),
          id: 'subtask-context-mark-open',
          key: 'mark-open',
          onClick: () => {
            onTaskStatusChange('OPEN')
          },
        },
        {
          content: (
            <>
              <CheckedCircleSolidIcon className="size-6" />
              Mark complete
            </>
          ),
          id: 'subtask-context-mark-complete',
          key: 'mark-complete',
          onClick: () => {
            onTaskStatusChange('COMPLETED')
          },
        },
        {
          content: (
            <>
              <CircleCrossFilledIcon className="size-6" />
              Mark cancelled
            </>
          ),
          id: 'subtask-context-mark-cancelled',
          key: 'mark-cancelled',
          onClick: () => {
            onTaskStatusChange('CANCELLED')
          },
        },
        {
          content: (
            <>
              <ArrowRightIcon className="size-6" />
              Open
              <span className="sr-only">{title} task</span>
            </>
          ),
          id: 'subtask-context-open',
          key: 'open',
          onClick: onOpenTask,
        },
        {
          content: (
            <>
              <TrashIcon className="size-6" />
              Delete
            </>
          ),
          id: 'subtask-context-delete',
          key: 'delete',
          onClick: onDelete,
        },
        {
          content: (
            <>
              <RenameIcon className="size-6" />
              Rename
            </>
          ),
          id: 'subtask-context-rename',
          key: 'rename',
          onClick: onRename,
        },
        {
          content: (
            <>
              <CopyLinkToClipboardIcon className="size-6" />
              Copy link
            </>
          ),
          id: 'subtask-context-clipboard-copy',
          key: 'copy',
          onClick: onCopyToClipboard,
        },
        {
          content: (
            <>
              <PasteClipboardIcon className="size-6" />
              Paste
            </>
          ),
          id: 'subtask-context-clipboard-paste',
          key: 'paste',
          onClick: onPasteTask,
        },
        {
          content: (
            <>
              <UnassignedIcon className="size-6" />
              Assign to…
            </>
          ),
          id: 'subtask-context-clipboard-assign',
          key: 'assign',
        },
      ],
      [
        title,
        onOpenTask,
        onDelete,
        onRename,
        onCopyToClipboard,
        onPasteTask,
        onTaskStatusChange,
      ]
    )

    const { users } = useUsers()
    const { updateTask } = useUpdateTask(taskId)

    const handleSelectAssignee = React.useCallback(
      (assigneeId: string) => {
        updateTask({
          // eslint-disable-next-line unicorn/no-null
          assigneeId: assigneeId === 'unassigned' ? null : assigneeId,
          id: taskId,
        })
      },
      [taskId, updateTask]
    )

    return (
      <ContextMenuPrimitive.Content
        {...rest}
        ref={reference}
        loop
        alignOffset={0}
        avoidCollisions
        data-testid={`subtask-context-menu-${taskId}`}
        className="z-50 w-63 rounded-lg bg-mono-paper p-1 shadow-just-shadow"
      >
        {popoverElements.map((element) => {
          if (element.key === 'assign') {
            return (
              <ContextMenuPrimitive.Sub key={element.key}>
                <ContextMenuPrimitive.SubTrigger
                  asChild
                  className="w-full justify-start hover:shadow-none focus:bg-sky-600 focus:text-mono-paper focus:shadow-none"
                >
                  <Button
                    size="sm"
                    variant="naked"
                    onClick={element.onClick}
                    data-testid={`${element.id}-${taskId}`}
                  >
                    {element.content}
                    <span className="ml-auto text-xl">›</span>
                  </Button>
                </ContextMenuPrimitive.SubTrigger>

                <ContextMenuPrimitive.Portal>
                  <ContextMenuPrimitive.SubContent
                    loop
                    className="z-50 max-h-108 w-50 overflow-auto rounded-lg bg-mono-paper p-1 shadow-just-shadow"
                    avoidCollisions
                    data-testid={`subtask-context-submenu-${taskId}`}
                  >
                    <ContextMenuPrimitive.Item
                      asChild
                      className="w-full justify-start hover:shadow-none focus:bg-sky-600 focus:text-mono-paper focus:shadow-none"
                      onClick={() => {
                        handleSelectAssignee('unassigned')
                      }}
                    >
                      <Button size="sm" variant="naked">
                        <Avatar
                          alt={'Unassigned'}
                          FallbackAvatarIcon={UnassignedIcon}
                          className="size-6 rounded-full"
                        />
                        No assignee
                        {!assigneeId && (
                          <RadioCircleSolidTinted
                            className="ml-auto"
                            innerCircleClass="fill-sky-50"
                            outerCircleClass="fill-sky-500"
                          />
                        )}
                      </Button>
                    </ContextMenuPrimitive.Item>

                    <ContextMenuPrimitive.Separator className="my-1 h-[1px] bg-film-normal" />

                    <p className="mt-1.5 mb-1 px-2 text-xs-bold text-mono-ink-subtle">
                      People
                    </p>
                    {users.map((user) => (
                      <ContextMenuPrimitive.Item
                        asChild
                        key={user.id}
                        className="w-full justify-start hover:shadow-none focus:bg-sky-600 focus:text-mono-paper focus:shadow-none"
                        onClick={() => {
                          handleSelectAssignee(user.id)
                        }}
                      >
                        <Button size="sm" variant="naked">
                          <Avatar
                            alt={user.name}
                            src={user.avatarUrl}
                            className="size-6 rounded-full"
                            data-testid={`dropdown-assignee-people-${user.id}-avatar`}
                          />
                          <span className="max-w-[65%] truncate">
                            {user.name}
                          </span>
                          {user.id === assigneeId && (
                            <RadioCircleSolidTinted
                              className="ml-auto"
                              innerCircleClass="fill-sky-50"
                              outerCircleClass="fill-sky-500"
                            />
                          )}
                        </Button>
                      </ContextMenuPrimitive.Item>
                    ))}
                  </ContextMenuPrimitive.SubContent>
                </ContextMenuPrimitive.Portal>
              </ContextMenuPrimitive.Sub>
            )
          }

          return (
            <ContextMenuPrimitive.Item
              asChild
              key={element.key}
              className="w-full justify-start hover:shadow-none focus:bg-sky-600 focus:text-mono-paper focus:shadow-none"
            >
              <Button
                size="sm"
                variant="naked"
                onClick={element.onClick}
                data-testid={`${element.id}-${taskId}`}
              >
                {element.content}
              </Button>
            </ContextMenuPrimitive.Item>
          )
        })}
      </ContextMenuPrimitive.Content>
    )
  }
)

SubtaskPopoverContent.displayName = 'SubtaskPopoverContent'
