import { DownIcon } from '@/components/icons/DownIcon'
import { Button } from '@/components/ui/button/Button'
import { Tooltip } from '@/components/ui/tooltip/Tooltip'
import { FileTypeIcon } from '@/features/task/components/chat/FileTypeIcon'
import { getFileType } from '@/features/task/components/chat/supportedFileTypes'
import { GetThreadEventsQuery } from '@/gql/generated/graphql'
import { getApiHost } from '@/lib/getApiHost'
import { formatBytes } from '@/lib/utils'

interface OtherFilesMessagePayloadProperties {
  files: Array<
    Extract<
      GetThreadEventsQuery['tasks'][number]['thread']['events'][number]['payload'][number],
      { __typename: 'FileMetadata' }
    >
  >
}

export const OtherFilesMessagePayload: React.FC<
  OtherFilesMessagePayloadProperties
> = ({ files }) => {
  return (
    <div className="grid grid-cols-[repeat(auto-fit,minmax(--container-sm,1fr))] gap-2">
      {files.map((file, index) => (
        <div
          key={file.fileUrl + index}
          className="flex h-12 w-sm items-center justify-between rounded-lg border border-film-normal bg-mono-paper p-1"
        >
          <div className="flex min-w-0 items-center gap-2">
            <FileTypeIcon
              fileType={file.mimeType}
              className="size-10 flex-shrink-0 rounded-md p-1.5"
            />
            <div className="flex min-w-0 flex-col justify-center">
              <label className="truncate text-sm font-medium text-mono-ink-strong">
                {file.name}
              </label>
              <label className="text-xs text-mono-ink-subtle">
                {getFileType(file.mimeType)} · {formatBytes(file.size)}
              </label>
            </div>
          </div>
          <Tooltip content="Download">
            <Button asChild className="size-8 min-h-8 min-w-8 rounded-full p-1">
              <a href={getApiHost() + file.fileUrl} download>
                <DownIcon className="fill-mono-ink-fade" />
              </a>
            </Button>
          </Tooltip>
        </div>
      ))}
    </div>
  )
}

OtherFilesMessagePayload.displayName = 'OtherFilesMessagePayload'
