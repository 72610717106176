import * as CheckboxPrimitive from '@radix-ui/react-checkbox'

import { CheckboxIcon } from '@/components/icons/CheckboxIcon'
import { cn } from '@/lib/utils'

interface Properties
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  id: string
  checked: boolean
  label?: string
  onCheck?: () => void
  className?: string
}

export const Checkbox = ({
  checked,
  className,
  id,
  label,
  onCheck,
  ...rest
}: Properties) => {
  return (
    <div
      className={cn('flex items-center space-x-2 overflow-hidden', className)}
    >
      <CheckboxPrimitive.Root
        id={id}
        className={cn(
          'relative flex h-[15px] w-[15px] min-w-[15px] items-center justify-center overflow-hidden rounded-[4px] border-[1.5px] border-black p-[2px]'
        )}
        onClick={onCheck}
        {...rest}
      >
        {checked ? (
          <>
            <CheckboxPrimitive.Indicator tabIndex={-1} />
            <CheckboxIcon className="size-5 shrink-0 text-black" />
          </>
        ) : (
          <div className="absolute top-[0.5px] left-[0.5px] h-[15px] w-[15px] rounded-[2px] border border-black/15" />
        )}
      </CheckboxPrimitive.Root>
      {label && (
        <label className="overflow-hidden text-nowrap text-ellipsis">
          {label}
        </label>
      )}
    </div>
  )
}

Checkbox.displayName = 'Checkbox'
