import {
  createFileRoute,
  Outlet,
  ScrollRestoration,
  ScrollRestorationOptions,
} from '@tanstack/react-router'
import { FC, Suspense, lazy } from 'react'
import { Flip, ToastContainer } from 'react-toastify'
import { z } from 'zod'

import { Breadcrumbs } from '@/components/layout/Breadcrumbs'
import { Stack } from '@/components/layout/Stack'
import { Navigation } from '@/components/layout/navigation/Navigation'

// eslint-disable-next-line unicorn/no-null
const NullComponent: FC = () => null
NullComponent.displayName = 'NullComponent'

/* v8 ignore next 10 */
const TanStackRouterDevtools =
  import.meta.env.PUBLIC_TANSTACK_ROUTER_DEV_TOOLS === 'true'
    ? lazy(
        async () =>
          await import('@tanstack/router-devtools').then(
            ({ TanStackRouterDevtools }) => ({
              default: TanStackRouterDevtools,
            })
          )
      )
    : NullComponent

/* v8 ignore next 10 */
const ReactQueryDevtoolsProduction =
  import.meta.env.PUBLIC_TANSTACK_QUERY_DEV_TOOLS === 'true'
    ? lazy(
        async () =>
          await import('@tanstack/react-query-devtools').then(
            ({ ReactQueryDevtools }) => ({
              default: ReactQueryDevtools,
            })
          )
      )
    : NullComponent

const getKey: ScrollRestorationOptions['getKey'] = (location) =>
  location.pathname

const PrivateLayout: FC = () => (
  <>
    <div
      className="relative flex h-screen w-[100vw] flex-row overflow-hidden"
      data-testid="root-container"
    >
      <Navigation />
      <div className="flex w-full flex-1 flex-col overflow-hidden">
        <Breadcrumbs />

        <ScrollRestoration getKey={getKey} />
        <Outlet />

        <ToastContainer
          draggable
          pauseOnHover
          position="bottom-right"
          transition={Flip}
        />
      </div>

      <Stack />
    </div>

    <Suspense>
      <TanStackRouterDevtools position="bottom-right" />
    </Suspense>

    <Suspense>
      <ReactQueryDevtoolsProduction />
    </Suspense>
  </>
)
PrivateLayout.displayName = 'PrivateLayout'

const stackSearchParameters = z.object({
  agents: z.optional(z.array(z.string())),
  filter: z.optional(z.enum(['all', 'open', 'concluded', 'cancelled'])),
  notes: z.optional(z.array(z.string())),
  stack: z.optional(z.array(z.string())),
})

export type SearchParameters = z.infer<typeof stackSearchParameters>

export const Route = createFileRoute('/_private')({
  beforeLoad: async function privateRouteLoader({ context }) {
    const user = await context.getUser()

    if (!user) {
      const redirectUrl = encodeURIComponent(location.href)
      location.replace(`${location.origin}/signin?redirect=${redirectUrl}`)
    }
  },
  component: PrivateLayout,
  validateSearch: stackSearchParameters,
})
