import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ClientError } from 'graphql-request'
import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { FetchNotesQuery } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'

const queryKey = ['notes']

export const useDeleteNote = () => {
  const api = useApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.deleteNote,
    onMutate: async (noteId) => {
      const previousState = queryClient.getQueryData<FetchNotesQuery>(queryKey)

      queryClient.setQueryData<FetchNotesQuery>(queryKey, (previousState) => {
        if (!previousState) return

        return {
          notes: previousState.notes.filter((note) => note.id !== noteId),
        }
      })

      return previousState
    },
    // Partition: Important to keep these definitions below onMutate property so the TS compiler infers context properly
    onError: (error, _variables, context) => {
      queryClient.setQueryData(queryKey, context)

      logger.error(error)

      if (error instanceof ClientError) {
        toast.error(error.response.errors?.[0]?.message)
      } else {
        toast.error('Note update failed!')
      }
    },
  })
}
