import * as SheetPrimitive from '@radix-ui/react-dialog'
import * as React from 'react'

import { CrossMediumIcon } from '@/components/icons/CrossMediumIcon'
import { Button } from '@/components/ui/button/Button'
import { cn } from '@/lib/utils'

const Sheet = SheetPrimitive.Root

const SheetTrigger = SheetPrimitive.Trigger

const SheetClose = SheetPrimitive.Close

const SheetPortal = SheetPrimitive.Portal

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...properties }, reference) => (
  <SheetPrimitive.Overlay
    className={cn(
      'fixed inset-0 left-25 z-50 bg-mono-ink-subtle/20 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:animate-in data-[state=open]:fade-in-0',
      className
    )}
    {...properties}
    ref={reference}
  />
))
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName

const SheetContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  React.PropsWithChildren<{ className?: string }>
>(({ className, ...properties }, reference) => (
  <SheetPortal>
    <SheetOverlay />
    <SheetPrimitive.Content
      ref={reference}
      className={cn(
        'fixed inset-y-0 left-25 z-10 h-full w-3/4 gap-4 overflow-y-auto border-r bg-mono-paper-darker shadow-lg transition ease-in-out data-[state=closed]:duration-300 data-[state=closed]:animate-out data-[state=closed]:slide-out-to-left data-[state=open]:duration-500 data-[state=open]:animate-in data-[state=open]:slide-in-from-left sm:max-w-[40rem]',
        className
      )}
      {...properties}
    />
  </SheetPortal>
))
SheetContent.displayName = SheetPrimitive.Content.displayName

const SheetHeader = ({
  className,
  ...properties
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex items-center gap-4 border-b border-film-normal/5 p-12 text-center sm:text-left',
      className
    )}
    {...properties}
  >
    <>
      <SheetPrimitive.Close
        className="data-[state=open]:bg-secondary rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:ring-2 focus:ring-ring focus:ring-offset-2 focus:outline-hidden disabled:pointer-events-none"
        asChild
        data-testid="dialog-close-button"
      >
        <Button
          variant={'subtle'}
          className="h-8 w-8 rounded-full p-0"
          size="sm"
        >
          <CrossMediumIcon className="h-5 w-5" />
          <span className="sr-only">Close</span>
        </Button>
      </SheetPrimitive.Close>
      {properties.children}
    </>
  </div>
)
SheetHeader.displayName = 'SheetHeader'

const SheetFooter = ({
  className,
  ...properties
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse border-t border-film-normal/5 p-6 sm:flex-row sm:justify-end sm:space-x-2',
      className
    )}
    {...properties}
  />
)
SheetFooter.displayName = 'SheetFooter'

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...properties }, reference) => (
  <SheetPrimitive.Title
    ref={reference}
    className={cn('text-3xl font-normal text-foreground', className)}
    {...properties}
  />
))
SheetTitle.displayName = SheetPrimitive.Title.displayName

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...properties }, reference) => (
  <SheetPrimitive.Description
    ref={reference}
    className={cn('text-muted-foreground text-sm', className)}
    {...properties}
  />
))
SheetDescription.displayName = SheetPrimitive.Description.displayName

export {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetOverlay,
  SheetPortal,
  SheetTitle,
  SheetTrigger,
}
