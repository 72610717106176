/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PrivateImport } from './routes/_private'
import { Route as AuthImport } from './routes/_auth'
import { Route as PrivateIndexImport } from './routes/_private/index'
import { Route as PrivateTeamImport } from './routes/_private/team'
import { Route as PrivateLibraryImport } from './routes/_private/library'
import { Route as PrivateAllWorkImport } from './routes/_private/all-work'
import { Route as AuthSigninImport } from './routes/_auth/signin'
import { Route as AuthAuthCallbackImport } from './routes/_auth/auth-callback'
import { Route as PrivateAllWorkIndexImport } from './routes/_private/all-work.index'
import { Route as PrivateAllWorkTaskIdImport } from './routes/_private/all-work.$taskId'
import { Route as PrivateAllWorkTaskIdIndexImport } from './routes/_private/all-work.$taskId.index'

// Create/Update Routes

const PrivateRoute = PrivateImport.update({
  id: '/_private',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const PrivateIndexRoute = PrivateIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/index.lazy').then((d) => d.Route),
)

const PrivateTeamRoute = PrivateTeamImport.update({
  id: '/team',
  path: '/team',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() => import('./routes/_private/team.lazy').then((d) => d.Route))

const PrivateLibraryRoute = PrivateLibraryImport.update({
  id: '/library',
  path: '/library',
  getParentRoute: () => PrivateRoute,
} as any).lazy(() =>
  import('./routes/_private/library.lazy').then((d) => d.Route),
)

const PrivateAllWorkRoute = PrivateAllWorkImport.update({
  id: '/all-work',
  path: '/all-work',
  getParentRoute: () => PrivateRoute,
} as any)

const AuthSigninRoute = AuthSigninImport.update({
  id: '/signin',
  path: '/signin',
  getParentRoute: () => AuthRoute,
} as any)

const AuthAuthCallbackRoute = AuthAuthCallbackImport.update({
  id: '/auth-callback',
  path: '/auth-callback',
  getParentRoute: () => AuthRoute,
} as any)

const PrivateAllWorkIndexRoute = PrivateAllWorkIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PrivateAllWorkRoute,
} as any).lazy(() =>
  import('./routes/_private/all-work.index.lazy').then((d) => d.Route),
)

const PrivateAllWorkTaskIdRoute = PrivateAllWorkTaskIdImport.update({
  id: '/$taskId',
  path: '/$taskId',
  getParentRoute: () => PrivateAllWorkRoute,
} as any).lazy(() =>
  import('./routes/_private/all-work.$taskId.lazy').then((d) => d.Route),
)

const PrivateAllWorkTaskIdIndexRoute = PrivateAllWorkTaskIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PrivateAllWorkTaskIdRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_private': {
      id: '/_private'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PrivateImport
      parentRoute: typeof rootRoute
    }
    '/_auth/auth-callback': {
      id: '/_auth/auth-callback'
      path: '/auth-callback'
      fullPath: '/auth-callback'
      preLoaderRoute: typeof AuthAuthCallbackImport
      parentRoute: typeof AuthImport
    }
    '/_auth/signin': {
      id: '/_auth/signin'
      path: '/signin'
      fullPath: '/signin'
      preLoaderRoute: typeof AuthSigninImport
      parentRoute: typeof AuthImport
    }
    '/_private/all-work': {
      id: '/_private/all-work'
      path: '/all-work'
      fullPath: '/all-work'
      preLoaderRoute: typeof PrivateAllWorkImport
      parentRoute: typeof PrivateImport
    }
    '/_private/library': {
      id: '/_private/library'
      path: '/library'
      fullPath: '/library'
      preLoaderRoute: typeof PrivateLibraryImport
      parentRoute: typeof PrivateImport
    }
    '/_private/team': {
      id: '/_private/team'
      path: '/team'
      fullPath: '/team'
      preLoaderRoute: typeof PrivateTeamImport
      parentRoute: typeof PrivateImport
    }
    '/_private/': {
      id: '/_private/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof PrivateIndexImport
      parentRoute: typeof PrivateImport
    }
    '/_private/all-work/$taskId': {
      id: '/_private/all-work/$taskId'
      path: '/$taskId'
      fullPath: '/all-work/$taskId'
      preLoaderRoute: typeof PrivateAllWorkTaskIdImport
      parentRoute: typeof PrivateAllWorkImport
    }
    '/_private/all-work/': {
      id: '/_private/all-work/'
      path: '/'
      fullPath: '/all-work/'
      preLoaderRoute: typeof PrivateAllWorkIndexImport
      parentRoute: typeof PrivateAllWorkImport
    }
    '/_private/all-work/$taskId/': {
      id: '/_private/all-work/$taskId/'
      path: '/'
      fullPath: '/all-work/$taskId/'
      preLoaderRoute: typeof PrivateAllWorkTaskIdIndexImport
      parentRoute: typeof PrivateAllWorkTaskIdImport
    }
  }
}

// Create and export the route tree

interface AuthRouteChildren {
  AuthAuthCallbackRoute: typeof AuthAuthCallbackRoute
  AuthSigninRoute: typeof AuthSigninRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthAuthCallbackRoute: AuthAuthCallbackRoute,
  AuthSigninRoute: AuthSigninRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface PrivateAllWorkTaskIdRouteChildren {
  PrivateAllWorkTaskIdIndexRoute: typeof PrivateAllWorkTaskIdIndexRoute
}

const PrivateAllWorkTaskIdRouteChildren: PrivateAllWorkTaskIdRouteChildren = {
  PrivateAllWorkTaskIdIndexRoute: PrivateAllWorkTaskIdIndexRoute,
}

const PrivateAllWorkTaskIdRouteWithChildren =
  PrivateAllWorkTaskIdRoute._addFileChildren(PrivateAllWorkTaskIdRouteChildren)

interface PrivateAllWorkRouteChildren {
  PrivateAllWorkTaskIdRoute: typeof PrivateAllWorkTaskIdRouteWithChildren
  PrivateAllWorkIndexRoute: typeof PrivateAllWorkIndexRoute
}

const PrivateAllWorkRouteChildren: PrivateAllWorkRouteChildren = {
  PrivateAllWorkTaskIdRoute: PrivateAllWorkTaskIdRouteWithChildren,
  PrivateAllWorkIndexRoute: PrivateAllWorkIndexRoute,
}

const PrivateAllWorkRouteWithChildren = PrivateAllWorkRoute._addFileChildren(
  PrivateAllWorkRouteChildren,
)

interface PrivateRouteChildren {
  PrivateAllWorkRoute: typeof PrivateAllWorkRouteWithChildren
  PrivateLibraryRoute: typeof PrivateLibraryRoute
  PrivateTeamRoute: typeof PrivateTeamRoute
  PrivateIndexRoute: typeof PrivateIndexRoute
}

const PrivateRouteChildren: PrivateRouteChildren = {
  PrivateAllWorkRoute: PrivateAllWorkRouteWithChildren,
  PrivateLibraryRoute: PrivateLibraryRoute,
  PrivateTeamRoute: PrivateTeamRoute,
  PrivateIndexRoute: PrivateIndexRoute,
}

const PrivateRouteWithChildren =
  PrivateRoute._addFileChildren(PrivateRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof PrivateRouteWithChildren
  '/auth-callback': typeof AuthAuthCallbackRoute
  '/signin': typeof AuthSigninRoute
  '/all-work': typeof PrivateAllWorkRouteWithChildren
  '/library': typeof PrivateLibraryRoute
  '/team': typeof PrivateTeamRoute
  '/': typeof PrivateIndexRoute
  '/all-work/$taskId': typeof PrivateAllWorkTaskIdRouteWithChildren
  '/all-work/': typeof PrivateAllWorkIndexRoute
  '/all-work/$taskId/': typeof PrivateAllWorkTaskIdIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthRouteWithChildren
  '/auth-callback': typeof AuthAuthCallbackRoute
  '/signin': typeof AuthSigninRoute
  '/library': typeof PrivateLibraryRoute
  '/team': typeof PrivateTeamRoute
  '/': typeof PrivateIndexRoute
  '/all-work': typeof PrivateAllWorkIndexRoute
  '/all-work/$taskId': typeof PrivateAllWorkTaskIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_private': typeof PrivateRouteWithChildren
  '/_auth/auth-callback': typeof AuthAuthCallbackRoute
  '/_auth/signin': typeof AuthSigninRoute
  '/_private/all-work': typeof PrivateAllWorkRouteWithChildren
  '/_private/library': typeof PrivateLibraryRoute
  '/_private/team': typeof PrivateTeamRoute
  '/_private/': typeof PrivateIndexRoute
  '/_private/all-work/$taskId': typeof PrivateAllWorkTaskIdRouteWithChildren
  '/_private/all-work/': typeof PrivateAllWorkIndexRoute
  '/_private/all-work/$taskId/': typeof PrivateAllWorkTaskIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/auth-callback'
    | '/signin'
    | '/all-work'
    | '/library'
    | '/team'
    | '/'
    | '/all-work/$taskId'
    | '/all-work/'
    | '/all-work/$taskId/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/auth-callback'
    | '/signin'
    | '/library'
    | '/team'
    | '/'
    | '/all-work'
    | '/all-work/$taskId'
  id:
    | '__root__'
    | '/_auth'
    | '/_private'
    | '/_auth/auth-callback'
    | '/_auth/signin'
    | '/_private/all-work'
    | '/_private/library'
    | '/_private/team'
    | '/_private/'
    | '/_private/all-work/$taskId'
    | '/_private/all-work/'
    | '/_private/all-work/$taskId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  PrivateRoute: typeof PrivateRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  PrivateRoute: PrivateRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/_private"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/auth-callback",
        "/_auth/signin"
      ]
    },
    "/_private": {
      "filePath": "_private.tsx",
      "children": [
        "/_private/all-work",
        "/_private/library",
        "/_private/team",
        "/_private/"
      ]
    },
    "/_auth/auth-callback": {
      "filePath": "_auth/auth-callback.tsx",
      "parent": "/_auth"
    },
    "/_auth/signin": {
      "filePath": "_auth/signin.tsx",
      "parent": "/_auth"
    },
    "/_private/all-work": {
      "filePath": "_private/all-work.tsx",
      "parent": "/_private",
      "children": [
        "/_private/all-work/$taskId",
        "/_private/all-work/"
      ]
    },
    "/_private/library": {
      "filePath": "_private/library.tsx",
      "parent": "/_private"
    },
    "/_private/team": {
      "filePath": "_private/team.tsx",
      "parent": "/_private"
    },
    "/_private/": {
      "filePath": "_private/index.tsx",
      "parent": "/_private"
    },
    "/_private/all-work/$taskId": {
      "filePath": "_private/all-work.$taskId.tsx",
      "parent": "/_private/all-work",
      "children": [
        "/_private/all-work/$taskId/"
      ]
    },
    "/_private/all-work/": {
      "filePath": "_private/all-work.index.tsx",
      "parent": "/_private/all-work"
    },
    "/_private/all-work/$taskId/": {
      "filePath": "_private/all-work.$taskId.index.tsx",
      "parent": "/_private/all-work/$taskId"
    }
  }
}
ROUTE_MANIFEST_END */
