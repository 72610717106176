import { KeyboardEvent, useCallback, useRef } from 'react'

import { CaretDownIcon } from '@/components/icons/CaretDownIcon'
import { CalendarIcon } from '@/features/task/components/CalendarIcon'
import { UpdateTaskInput } from '@/gql/generated/graphql'

interface TaskDueAtSelectProperties {
  onDateChange: (formData: Pick<UpdateTaskInput, 'dueAt'>) => void
  isPending: boolean
  dueAt?: string
}

export const TaskDueAtSelect = ({
  dueAt,
  isPending,
  onDateChange,
}: TaskDueAtSelectProperties) => {
  const dateInputReference = useRef<HTMLInputElement>(null)

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault()
        dateInputReference.current?.showPicker()
      }
    },
    [dateInputReference]
  )

  const handleTriggerClick = useCallback(() => {
    dateInputReference.current?.showPicker()
  }, [dateInputReference])

  const handleDateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onDateChange({
        // eslint-disable-next-line unicorn/no-null
        dueAt: event.target.value || null,
      })
    },
    [onDateChange]
  )

  const normalizedDueDate = (): string => {
    return dueAt?.split('T')?.[0] ?? ''
  }

  const localizedDueDate = (): string => {
    return dueAt
      ? new Date(dueAt).toLocaleDateString(undefined, {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        })
      : 'Due date'
  }

  return (
    <label
      className="relative flex h-8 flex-1 cursor-pointer items-center rounded-md border bg-film-subtle px-1 hover:border-film-subtle hover:bg-film-normal focus-visible:bg-sky-50 focus-visible:text-blue-ink-strong focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-0 focus-visible:outline-sky-300"
      data-testid="button-show-datepicker"
      tabIndex={0}
      onClick={handleTriggerClick}
      onKeyDown={handleKeyDown}
    >
      <CalendarIcon className="size-6 min-h-6 min-w-6 text-gray-500" />
      <span
        data-testid="task-duedate-value"
        className="ml-2 grow text-left text-sm-regular text-gray-700"
      >
        {localizedDueDate()}
      </span>

      <CaretDownIcon />

      <input
        type="date"
        ref={dateInputReference}
        className="absolute inset-0 top-2 h-full w-full cursor-pointer opacity-0"
        value={normalizedDueDate()}
        onChange={handleDateChange}
        disabled={isPending}
        aria-label="Select Due Date"
        data-testid="input-duedate"
        tabIndex={-1}
      />
    </label>
  )
}

TaskDueAtSelect.displayName = 'TaskDueAtSelect'
