import { Link } from '@tanstack/react-router'
import { useMemo } from 'react'

import { Avatar } from '@/components/ui/avatar/Avatar'
import { Checkbox } from '@/components/ui/checkbox/Checkbox'
import { TaskListItem } from '@/components/ui/task-list-item/TaskListItem'
import { ArrowRightIcon } from '@/features/task/components/subtasks/ArrowRightIcon'
import { FetchTasksByIdQuery } from '@/gql/generated/graphql'

interface SubtaskDialogItemProperties {
  assignee?: FetchTasksByIdQuery['tasks'][number]['subtasks'][number]['assignee']
  taskId: string
  title: string
  className?: string
  isCompleted: boolean
}

export const SubtaskDialogItem = ({
  assignee,
  isCompleted,
  taskId,
  title,
}: SubtaskDialogItemProperties) => {
  const linkParameters = useMemo(
    () => ({
      taskId,
    }),
    [taskId]
  )

  return (
    <TaskListItem
      size={'medium'}
      className="mb-2"
      start={useMemo(
        () => (
          <Checkbox
            id={taskId}
            data-testid="checkbox"
            checked={isCompleted}
            disabled
          />
        ),
        [isCompleted, taskId]
      )}
      end={useMemo(
        () => (
          <div className="flex gap-2">
            {assignee && (
              <Avatar
                src={assignee.avatarUrl}
                alt={assignee.name}
                tooltip={assignee.name}
                boringAvatarSeed={assignee.id}
                className="size-5 max-w-none rounded"
              />
            )}
            <Link
              data-testid={`open-subtask-${taskId}`}
              className="z-10 flex h-6 w-6 min-w-6 items-center justify-center rounded-full bg-film-subtle focus:rounded-lg focus:bg-sky-50 focus:outline focus:outline-sky-300"
              to="/all-work/$taskId"
              params={linkParameters}
            >
              <ArrowRightIcon className="size-3" />
              <span className="sr-only">Go to {title} task</span>
            </Link>
          </div>
        ),
        [assignee, taskId, title, linkParameters]
      )}
    >
      {title}
    </TaskListItem>
  )
}

SubtaskDialogItem.displayName = 'SubtaskDialogItem'
