import { IconComponent } from '@/lib/types'

export const FilePdfDocumentIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...properties}
    >
      <path
        fill="inherit"
        d="M6.25 2A2.26 2.26 0 0 0 4 4.25v15.5A2.26 2.26 0 0 0 6.25 22h11.5A2.26 2.26 0 0 0 20 19.75V9.25a.75.75 0 0 0-.219-.531l-6.5-6.5A.75.75 0 0 0 12.75 2zm0 1.5H12v4.25A2.26 2.26 0 0 0 14.25 10h4.25v9.75c0 .424-.326.75-.75.75H6.25a.737.737 0 0 1-.75-.75V4.25c0-.424.326-.75.75-.75m7.25 1.06 3.94 3.94h-3.19a.737.737 0 0 1-.75-.75zm-2.25 5.94c-.685 0-1.25.565-1.25 1.25 0 .872.445 1.813 1.026 2.716-.151.49-.315.972-.508 1.404q-.008.002-.015.005c-1.266.484-2.157 1.042-2.625 1.48a1.22 1.22 0 0 0-.378.895 1.253 1.253 0 0 0 1.25 1.25c.346 0 .63-.156.846-.33.263-.214.519-.54.823-.996.263-.395.544-.916.815-1.473.433-.152.894-.292 1.401-.402.79.667 1.654 1.201 2.615 1.201.685 0 1.25-.565 1.25-1.25S15.935 15 15.25 15c-.83 0-1.596.099-2.323.23-.17-.162-.36-.27-.513-.454-.117-.138-.177-.294-.281-.44a10 10 0 0 0 .367-2.586c0-.685-.565-1.25-1.25-1.25m0 1c.143 0 .25.107.25.25 0 .409-.104.81-.167 1.219-.185-.453-.333-.896-.333-1.219 0-.143.107-.25.25-.25m.469 3.984c.01.013.023.019.034.032l-.05.015zM15.25 16c.143 0 .25.107.25.25s-.107.25-.25.25c-.352 0-.747-.187-1.14-.388.382-.036.731-.112 1.14-.112m-5.51 1.341c-.05.08-.105.206-.154.279-.274.409-.531.7-.62.773-.107.086-.18.107-.216.107a.25.25 0 0 1-.25-.25c0-.073.023-.13.06-.164l.002-.003c.183-.171.628-.448 1.178-.742"
      />
    </svg>
  )
}

FilePdfDocumentIcon.displayName = 'FilePdfDocumentIcon'
