import { useCallback, useState } from 'react'

import { FileListItem } from '@/features/task/components/chat/ChatMessageInput'
import { BeforeRequest, fileUploadCreator, UploadState } from '@/lib/fileUpload'

export const useFileUpload = (beforeRequest: BeforeRequest) => {
  const [filesState, setFilesState] = useState<Record<string, UploadState>>({})

  const upload = useCallback(
    (fileListItems: FileListItem[]) => {
      for (const fileListItem of fileListItems) {
        const fileUpload = fileUploadCreator({
          beforeRequest,
          setState: (state) => {
            setFilesState((previous) => ({
              ...previous,
              [fileListItem.id]: state,
            }))
          },
          updateState: (updateState) => {
            setFilesState((previousFilesState) => {
              const previousFileState = previousFilesState[fileListItem.id]

              return previousFileState
                ? {
                    ...previousFilesState,
                    [fileListItem.id]: updateState(previousFileState),
                  }
                : previousFilesState
            })
          },
        })

        fileUpload({ files: [fileListItem.file] })
      }
    },
    [beforeRequest, setFilesState]
  )

  return [upload, filesState, setFilesState] as const
}
