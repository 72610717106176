import { FC } from 'react'

import { Avatar } from '@/components/ui/avatar/Avatar'
import { useViewer } from '@/lib/hooks/useViewer'

interface ViewerAvatarProperties {
  className?: string
}

export const ViewerAvatar: FC<ViewerAvatarProperties> = ({ className }) => {
  const { viewer } = useViewer()

  return (
    <Avatar
      className={className}
      alt={viewer.name}
      src={viewer.avatarUrl}
      boringAvatarSeed={viewer.id}
    />
  )
}

ViewerAvatar.displayName = 'ViewerAvatar'
