import { useSuspenseQuery } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'

import { applyFilter } from '@/components/ui/view-organiser-bar/ViewOrganiserBar'
import { useApi } from '@/contexts/ApiProvider'
import { SearchParameters } from '@/routes/_private'

interface CreateQueryOptionParameters {
  api: RouteContext['api']
}

export const createAllWorkQueryOptions = ({
  api,
}: CreateQueryOptionParameters) => ({
  queryFn: async () => {
    return await api.fetchAllWork()
  },
  queryKey: ['tasks'],
})

interface UseAllWorkProperties {
  filter?: SearchParameters['filter']
}

export const useAllWork = (properties?: UseAllWorkProperties) => {
  const api = useApi()

  const queryOptions = createAllWorkQueryOptions({ api })

  const { data } = useSuspenseQuery(queryOptions)

  const filteredTasks = data.filter((task) =>
    applyFilter({ filter: properties?.filter, status: task.status })
  )

  return { tasks: filteredTasks }
}
