import { IconComponent } from '@/lib/types'

export const DownIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...properties}
    >
      <path
        fill="inherit"
        d="M12.237 2.99a.75.75 0 0 0-.737.76v14.69L5.781 12.72a.76.76 0 0 0-.731-.206.75.75 0 0 0-.537.537.76.76 0 0 0 .206.731l7 7a.754.754 0 0 0 1.062 0l7-7a.76.76 0 0 0 .206-.732.75.75 0 0 0-.536-.536.76.76 0 0 0-.732.206L13 18.44V3.75a.75.75 0 0 0-.763-.76"
      />
    </svg>
  )
}

DownIcon.displayName = 'DownIcon'
