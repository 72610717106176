import { Link, LinkComponentProps } from '@tanstack/react-router'

import { TaskStatus } from '@/gql/generated/graphql'
import { cn } from '@/lib/utils'
import { SearchParameters } from '@/routes/_private'

interface ViewOrganiserBarProperties {
  activeFilter: SearchParameters['filter']
  search: Exclude<LinkComponentProps['search'], boolean | undefined>
}

const FILTERS = ['All', 'Open', 'Concluded', 'Cancelled']

const filterConditions: Record<string, (status: TaskStatus) => boolean> = {
  cancelled: (status) => status === 'CANCELLED',
  concluded: (status) => status === 'COMPLETED' || status === 'CANCELLED',
  open: (status) => status === 'OPEN',
}

export const applyFilter = ({
  filter,
  status,
}: {
  status: TaskStatus
  filter?: string
}) => {
  const normalizedFilter = filter?.toLowerCase()
  return !normalizedFilter || normalizedFilter === 'all'
    ? true
    : (filterConditions[normalizedFilter]?.(status) ?? true)
}

export const ViewOrganiserBar = ({
  activeFilter,
  search,
}: ViewOrganiserBarProperties) => {
  return (
    <div className="flex items-center gap-1" data-testid="view-organiser-bar">
      {FILTERS.map((filter, index) => {
        const filterValue = filter.toLowerCase()
        const isActive = activeFilter === filterValue

        return (
          <Link
            key={filter}
            /* eslint-disable-next-line react-memo/require-usememo */
            search={{
              ...search,
              filter: filterValue,
            }}
            to=""
            className={cn(
              'flex h-7.5 min-w-15 items-center justify-center rounded-xs border border-film-strong p-2.5 text-center text-sm-regular text-mono-ink-subtle',
              {
                'border-film-stronger bg-film-strong !text-mono-ink-strong':
                  isActive,
                'rounded-l-4xl': index === 0,
                'rounded-r-4xl': index === FILTERS.length - 1,
              }
            )}
          >
            {filter}
          </Link>
        )
      })}
    </div>
  )
}

ViewOrganiserBar.displayName = 'ViewOrganiserBar'
