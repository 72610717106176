import { useSuspenseQuery } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'

import { applyFilter } from '@/components/ui/view-organiser-bar/ViewOrganiserBar'
import { useApi } from '@/contexts/ApiProvider'
import { SearchParameters } from '@/routes/_private'

interface CreateQueryOptionParameters {
  api: RouteContext['api']
}

export const createViewerQueryOptions = ({
  api,
}: CreateQueryOptionParameters) => {
  return {
    queryFn: api.fetchViewerWithTasks,
    queryKey: ['viewerWithTasks'],
  }
}

interface UseViewerWithTasksProperties {
  filter?: SearchParameters['filter']
}

export const useViewerWithTasks = (
  properties?: UseViewerWithTasksProperties
) => {
  const api = useApi()

  const queryOptions = createViewerQueryOptions({ api })

  const { data } = useSuspenseQuery(queryOptions)

  const filteredViewerTasks = data.viewer.tasks.filter((task) =>
    applyFilter({ filter: properties?.filter, status: task.status })
  )

  return { viewer: { ...data.viewer, tasks: filteredViewerTasks } }
}
