import { FilePdfDocumentIcon } from '@/components/icons/FilePdfDocumentIcon'
import { IconComponent } from '@/lib/types'

export const supportedFileTypesIcon: Record<
  string,
  {
    icon: IconComponent
    iconClassName: string
    wrapperClassName: string
    name: string
  }
> = {
  'application/pdf': {
    icon: FilePdfDocumentIcon,
    iconClassName: 'fill-red-800',
    name: 'PDF',
    wrapperClassName: 'bg-red-200',
  },
}

export const getFileType = (mimeType: string) =>
  supportedFileTypesIcon[mimeType]?.name ?? 'File'
