import { Link, LinkProps, useParams } from '@tanstack/react-router'
import { useCallback, useMemo, Suspense } from 'react'

import { ForwardSlash } from '@/components/icons/ForwardSlash'
import { ThreeDotsVerticalIcon } from '@/components/icons/ThreeDotsVerticalIcon'
import { Skeleton } from '@/components/ui/skeleton/Skeleton'
import { TaskIcon } from '@/features/task/components/TaskIcon'
import { useTask } from '@/features/task/hooks/useTask'
import { TASK_ROUTE_PATH } from '@/features/task/utils/constants'
import { useDocumentTitle } from '@/lib/hooks/useDocumentTitle'
import { IconComponent } from '@/lib/types'
import { cn } from '@/lib/utils'

interface Properties extends LinkProps {
  Icon: IconComponent
  textClassName?: string
  title: string
  className?: string
}

const activeProperties = { className: 'pointer-events-none' }
const Title = ({
  Icon,
  className,
  textClassName,
  title,
  ...properties
}: Properties) => {
  return (
    <Link
      {...properties}
      className={cn(
        'flex flex-row items-center gap-0.5 rounded-sm p-0.5 text-xs-regular opacity-50 hover:opacity-100',
        className
      )}
      title={title}
      activeProps={activeProperties}
    >
      <Icon className="size-4 min-w-4" />
      <span className={cn('w-full max-w-80 grow truncate', textClassName)}>
        {title}
      </span>
    </Link>
  )
}
Title.displayName = 'Title'

const BreadcrumbComponent = () => {
  const { taskId } = useParams({ from: TASK_ROUTE_PATH })
  const { task } = useTask(taskId)
  useDocumentTitle(task.title)
  const ParentTaskIcon = useCallback(
    () => <TaskIcon status={task.parentTask?.status} size="sm" />,
    [task.parentTask]
  )

  const CurrentTaskIcon = useCallback(
    () => <TaskIcon status={task.status} size="sm" />,
    [task.status]
  )

  const parentTaskParameters = useMemo(
    () => ({ taskId: task.parentTask?.id }),
    [task.parentTask?.id]
  )
  const currentTaskParameters = useMemo(() => ({ taskId: task.id }), [task.id])

  return (
    <>
      {!!task.parentTask?.parentTask && (
        <>
          <ThreeDotsVerticalIcon
            className={'rotate-90 opacity-50 duration-300 animate-in fade-in'}
            data-testid="granparent-breadcrumb"
          />
          <ForwardSlash
            className="size-4 opacity-[0.18] duration-300 animate-in fade-in"
            data-testid="granparent-breadcrumb-slash"
          />
        </>
      )}

      {!!task.parentTask && (
        <>
          <Title
            Icon={ParentTaskIcon}
            title={task.parentTask.title}
            data-testid="parent-task-breadcrumb"
            to="/all-work/$taskId"
            className="duration-300 animate-in fade-in"
            params={parentTaskParameters}
          />

          <ForwardSlash className="size-4 opacity-[0.18]" />
        </>
      )}

      <Title
        Icon={CurrentTaskIcon}
        title={task.title}
        className="duration-300 animate-in fade-in"
        data-testid="task-breadcrumb"
        to="/all-work/$taskId"
        params={currentTaskParameters}
      />
    </>
  )
}

BreadcrumbComponent.displayName = 'TaskBreadcrumb'

const SuspendedBreadcrumbComponent = () => {
  const skeleton = useMemo(
    () => (
      <div className="flex flex-row items-center gap-0.5">
        <Skeleton className="size-4 rounded-full bg-film-normal" />
        <Skeleton className="h-4 w-[35px] rounded-xs bg-film-normal" />
        <Skeleton className="h-4 w-[20px] rounded-xs bg-film-normal" />
        <Skeleton className="h-4 w-[100px] rounded-xs bg-film-normal" />
      </div>
    ),
    []
  )

  return (
    <Suspense fallback={skeleton}>
      <BreadcrumbComponent />
    </Suspense>
  )
}

SuspendedBreadcrumbComponent.displayName = 'SuspendedBreadcrumbComponent'

export const TaskBreadcrumb = Object.assign(SuspendedBreadcrumbComponent, {
  Title,
})
