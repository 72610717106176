import { FC } from 'react'

import { CheckedCircleSolidIcon } from '@/components/icons/CheckedCircleSolidIcon'
import { CircleCrossFilledIcon } from '@/components/icons/CircleCrossFilledIcon'
import { PlusMathIcon } from '@/components/icons/PlusMathIcon'
import { CircleIcon } from '@/features/task/components/chat/CircleIcon'
import { GetThreadEventsQuery } from '@/gql/generated/graphql'
import { TaskUpdateType } from '@/tests/generated-api-types'

interface ThreadStatus {
  icon: React.ReactNode
  text: string
}

const threadStatus: Record<TaskUpdateType, ThreadStatus> = {
  CREATED: {
    icon: <PlusMathIcon className="h-4 w-4 text-white" />,
    text: 'created task',
  },
  STATUS_CANCELLED: {
    icon: <CircleCrossFilledIcon className="text-white" />,
    text: 'marked task as cancelled',
  },
  STATUS_COMPLETED: {
    icon: <CheckedCircleSolidIcon className="text-white" />,
    text: 'marked task as complete',
  },
  STATUS_OPENED: { icon: <CircleIcon />, text: 'marked task as open' },
}

interface ChatMessagePayloadProperties {
  taskUpdate: Extract<
    GetThreadEventsQuery['tasks'][number]['thread']['events'][number]['payload'][number],
    { __typename: 'TaskUpdate' }
  >
}

export const TaskUpdateMessagePayload: FC<ChatMessagePayloadProperties> = ({
  taskUpdate,
}) => {
  return (
    <div className="flex w-fit max-w-full items-center gap-1 truncate rounded-[66px] bg-[#ECE8E1] p-1.5 pr-3">
      <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gold p-1">
        {threadStatus[taskUpdate.type].icon}
      </div>

      <div className="flex w-full flex-col truncate">
        <p className="w-full truncate text-sm font-medium text-[#694C2F]">
          {taskUpdate.affectedTask.title}
        </p>
        {!!taskUpdate.affectedTask?.description && (
          <p className="w-full truncate text-xs-regular text-mono-ink-subtle">
            {taskUpdate.affectedTask.description}
          </p>
        )}
      </div>
    </div>
  )
}

TaskUpdateMessagePayload.displayName = 'TaskUpdateMessagePayload'

export const TaskUpdateMessagePayloadForUser: FC<
  ChatMessagePayloadProperties
> = ({ taskUpdate }) => {
  return (
    <>
      <p className="text-sm-regular text-mono-ink-subtle">
        {threadStatus[taskUpdate.type].text}
      </p>
      <div className="h-0.75 w-0.75 rounded-full bg-film-strongest" />
    </>
  )
}

TaskUpdateMessagePayloadForUser.displayName = 'TaskUpdateMessagePayloadForUser'
