import { ChatMessage } from '@/features/task/components/chat/ChatMessage'
import { FetchAllUsersQuery } from '@/gql/generated/graphql'

interface ChatMessagePayloadProperties {
  users: FetchAllUsersQuery['users']
  message: string
}

export function ChatMessagePayload({
  message,
  users,
}: ChatMessagePayloadProperties) {
  return (
    <div className="flex gap-3 bg-none">
      <div className="flex flex-col items-start gap-2 truncate">
        <p className="min-w-1 whitespace-pre-wrap">
          <ChatMessage message={message} users={users} />
        </p>
      </div>
    </div>
  )
}

ChatMessagePayload.displayName = 'ChatMessagePayload'
