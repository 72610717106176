import { useMutation } from '@tanstack/react-query'

import { useTaskDetailPageContext } from '@/features/task/hooks/useTaskDetailPageContext'
import {
  CreateMessageInput,
  GetThreadEventsQuery,
} from '@/gql/generated/graphql'

type ThreadEvents = GetThreadEventsQuery['tasks'][number]

interface UseCreateChatMessageProperties {
  handleMutate?: (
    variables: CreateMessageInput
  ) => Promise<ThreadEvents | undefined>
  handleError?: (
    error: Error,
    _variables: CreateMessageInput,
    previousThread?: ThreadEvents
  ) => Promise<unknown>
  handleSettled?: () => void
}

export const useCreateChatMessage = ({
  handleError,
  handleMutate,
  handleSettled,
}: UseCreateChatMessageProperties) => {
  const { api } = useTaskDetailPageContext()

  const createMessageMutation = useMutation({
    mutationFn: api.createMessage,
    mutationKey: ['tasks', 'threads', 'messages'],
    onError: handleError,
    onMutate: handleMutate,
    onSettled: handleSettled,
  })

  return {
    createNewMessage: createMessageMutation.mutateAsync,
    isPending: createMessageMutation.isPending,
  }
}
