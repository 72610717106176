import { createFileRoute } from '@tanstack/react-router'

import { TaskTodoStackIcon } from '@/components/icons/TaskTodoStackIcon'
import { Api } from '@/contexts/ApiProvider'
import { createAllWorkQueryOptions } from '@/features/all-work/hooks/useAllWork'

export interface AllWorkLoaderData {
  tasks: Awaited<ReturnType<Api['fetchAllWork']>>
}

export const Route = createFileRoute('/_private/all-work/')({
  loader: async ({ context }): Promise<AllWorkLoaderData> => ({
    tasks: await context.queryClient.ensureQueryData(
      createAllWorkQueryOptions(context)
    ),
  }),
})

Route.interloom = {
  NavigationIcon: TaskTodoStackIcon,
  defaultPageTitle: 'All Work',
  tooltipText: 'All Work',
}
