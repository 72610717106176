import { useCallback, FC, PropsWithChildren } from 'react'

import { PlusMathIcon } from '@/components/icons/PlusMathIcon'
import { Button, ButtonProperties } from '@/components/ui/button/Button'
import { useCreateTask } from '@/features/task/hooks/useCreateTask'
import { useViewer } from '@/lib/hooks/useViewer'

interface CreateTaskButtonProperties
  extends ButtonProperties,
    PropsWithChildren {
  assignCurrentUser?: boolean
}

export const CreateTaskButton: FC<CreateTaskButtonProperties> = ({
  assignCurrentUser,
  children,
  ...rest
}) => {
  const { viewer } = useViewer()
  const { createTask } = useCreateTask()

  const createNewTask = useCallback(() => {
    createTask({
      ...(assignCurrentUser && { assigneeId: viewer.id }),
      title: 'New Task',
    })
  }, [assignCurrentUser, createTask, viewer.id])

  return (
    <Button onClick={createNewTask} data-testid="button-plus-item" {...rest}>
      <PlusMathIcon />
      {children}
    </Button>
  )
}

CreateTaskButton.displayName = 'CreateTaskButton'
