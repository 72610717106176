import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useRouter } from '@tanstack/react-router'
import { FC, Suspense, useCallback } from 'react'

import { BellNotificationIcon } from '@/components/icons/BellNotificationIcon'
import { CrossLargeIcon } from '@/components/icons/CrossLargeIcon'
import { UnreadIndicatorIcon } from '@/components/icons/UnreadIndicatorIcon'
import { FeedbackButton } from '@/components/layout/navigation/FeedbackButton'
import { NavigationHorizontalDash } from '@/components/layout/navigation/NavigationHorizontalDash'
import { NavigationItem } from '@/components/layout/navigation/NavigationItem'
import { ViewerAvatar } from '@/components/ui/avatar/ViewerAvatar'
import { Button } from '@/components/ui/button/Button'
import * as PopoverPrimitive from '@/components/ui/popover/Popover'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet/sheet'
import { useOIDCAuth } from '@/contexts/OidcAuthContext'
import { NotificationList } from '@/features/notification/components/NotificationList'
import { useNotificationPull } from '@/features/notification/hooks/useNotificationPull'
import { CreateTaskButton } from '@/features/task/components/CreateTaskButton'
import { useBoolean } from '@/lib/hooks/useBoolean'
import { useViewer } from '@/lib/hooks/useViewer'
import { ValidRoute } from '@/router'

const NavigationIcon: FC<{ path: ValidRoute; className?: string }> = ({
  className,
  path,
}) => {
  const router = useRouter()
  const NavigationIcon = router.routesByPath[path]?.interloom?.NavigationIcon

  if (!NavigationIcon) {
    throw new Error(`NavigationIcon not defined for the route "${path}"!`)
  }

  return <NavigationIcon className={className} />
}
NavigationIcon.displayName = 'NavigationIcon'

export const Navigation: FC = () => {
  const router = useRouter()
  const { viewer } = useViewer()
  const { signOut } = useOIDCAuth()
  const userActionsPopoverControl = useBoolean(false)
  const handleSignOut = useCallback(() => {
    void signOut()
    userActionsPopoverControl.setFalse()
  }, [signOut, userActionsPopoverControl])
  const notificationSidebarControl = useBoolean(false)
  const { hasUnreadNotifications } = useNotificationPull(
    notificationSidebarControl.value
  )

  return (
    <nav className="relative z-20 flex min-h-full w-25 min-w-25 flex-col items-center justify-between bg-mono-field-border py-4">
      <div className="flex flex-col items-center gap-2">
        <NavigationItem
          data-testid="NavigationItem-/"
          to="/"
          tooltipText={router.routesByPath['/'].interloom?.tooltipText}
        >
          <NavigationIcon path="/" className="size-6" />
        </NavigationItem>

        <>
          <NavigationHorizontalDash />
          <CreateTaskButton
            data-testid="NavigationItem-new-task"
            assignCurrentUser
          />
          <NavigationHorizontalDash />
        </>

        <NavigationItem
          data-testid="NavigationItem-/all-work"
          to="/all-work"
          tooltipText={router.routesByPath['/all-work'].interloom?.tooltipText}
        >
          <NavigationIcon path="/all-work" className="size-6" />
        </NavigationItem>

        <NavigationItem
          data-testid="NavigationItem-/team"
          to="/team"
          tooltipText={router.routesByPath['/team'].interloom?.tooltipText}
        >
          <NavigationIcon path="/team" className="size-6" />
        </NavigationItem>

        <NavigationItem
          data-testid="NavigationItem-/library"
          to="/library"
          tooltipText={router.routesByPath['/library'].interloom?.tooltipText}
        >
          <NavigationIcon path="/library" className="size-6" />
        </NavigationItem>

        <NavigationHorizontalDash />
      </div>
      <div className="flex flex-col items-center gap-2 overflow-hidden">
        <PopoverPrimitive.Popover
          onOpenChange={userActionsPopoverControl.setValue}
          open={userActionsPopoverControl.value}
        >
          <PopoverPrimitive.PopoverTrigger
            className="rounded-full hover:opacity-80 hover:shadow-md"
            data-testid="user-actions-trigger"
          >
            <ViewerAvatar className="size-10 rounded-full" />
          </PopoverPrimitive.PopoverTrigger>
          <PopoverPrimitive.PopoverPortal>
            <PopoverPrimitive.PopoverContent
              onPointerDownOutside={userActionsPopoverControl.setFalse}
              onEscapeKeyDown={userActionsPopoverControl.setFalse}
              side="top"
              useTriggerWidth={false}
              className="z-50 w-[30rem] max-w-[100vw]"
              innerContainerClassName="p-0"
              data-testid="user-actions-popover"
            >
              <div className="flex w-full justify-end p-4">
                <Button
                  variant="subtle"
                  size="sm"
                  onClick={userActionsPopoverControl.setFalse}
                  data-testid="user-actions-popover-cancel-button"
                >
                  <CrossLargeIcon className="size-4" />
                </Button>
              </div>
              <div className="h-[1px] w-full bg-film-normal" />
              <div className="flex flex-row items-center gap-2 p-4">
                <ViewerAvatar className="size-10 w-full rounded-full" />
                <p className="text-xl font-bold text-mono-ink-strong">
                  {viewer.name}
                </p>
              </div>
              <div className="h-[1px] w-full bg-film-normal" />
              <div className="flex w-full justify-end p-4">
                <Button
                  variant="subtle"
                  size="sm"
                  onClick={handleSignOut}
                  data-testid="user-actions-popover-signout-button"
                >
                  Sign out
                </Button>
              </div>
            </PopoverPrimitive.PopoverContent>
          </PopoverPrimitive.PopoverPortal>
        </PopoverPrimitive.Popover>
        <Sheet
          open={notificationSidebarControl.value}
          onOpenChange={notificationSidebarControl.toggle}
        >
          <SheetTrigger asChild>
            <Button
              data-testid="notifications-trigger"
              className="relative"
              aria-label="Notifications"
            >
              <BellNotificationIcon className="size-6" />
              {hasUnreadNotifications && (
                <UnreadIndicatorIcon
                  className="absolute top-1.5 right-1.5 size-2 text-blue-ink-subtle"
                  aria-label="Unread notifications"
                  data-testid="unread-notifications-indicator"
                />
              )}
            </Button>
          </SheetTrigger>
          <SheetContent
            data-testid="notifications-dialog"
            className="z-50 flex flex-col"
          >
            <SheetHeader>
              <SheetTitle>Notifications</SheetTitle>
              <SheetDescription>
                <VisuallyHidden>User notifications</VisuallyHidden>
              </SheetDescription>
            </SheetHeader>
            <Suspense>
              <NotificationList
                hideNotificationSidebar={notificationSidebarControl.setFalse}
              />
            </Suspense>
          </SheetContent>
        </Sheet>
        <div>
          <FeedbackButton />
          <div
            className="pt-2 text-center text-xs opacity-30"
            title={import.meta.env.PUBLIC_GIT_TIMESTAMP}
          >
            v{import.meta.env.PUBLIC_VERSION}
          </div>
        </div>
      </div>
    </nav>
  )
}

Navigation.displayName = 'Navigation'
